import React, { Suspense } from "react";
import { useLocation, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/chakra";
import { Fonts } from "./theme/fonts";
import ProtectedComponent from "./auth/ProtectedComponent";

import { use } from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./locale";
import { DEFAULT_LOCALE } from "./config";

import { HF_tenant_key } from "./dealerAccounts/types";

import Layout from "./shared/Layout";
import DealerAccountNotifications from "./dealerAccounts/subRoutes/DealerAccountNotifications";
import CreateSystemMessagePage from "./systemMessages/routes/CreateSystemMessagePage";

const SignInPage = React.lazy(() => import("./auth/routes/SignInPage"));
const AuthCallbackPage = React.lazy(() => import("./auth/routes/AuthCallbackPage"));

const PasswordResetPage = React.lazy(() => import("./admin/routes/PasswordResetPage"));
const SendEmailPage = React.lazy(() => import("./emails/routes/SendEmailPage"));
const EmailsPage = React.lazy(() => import("./emails/routes/EmailsPage"));
const EmailDetailsPage = React.lazy(() => import("./emails/routes/EmailDetailsPage"));
const CreateUserPage = React.lazy(() => import("./admin/routes/CreateUserPage"));
const DashboardPage = React.lazy(() => import("./dashboard/routes/DashboardPage"));
const DealsPage = React.lazy(() => import("./deals/routes/DealsPage"));
const DealPage = React.lazy(() => import("./deals/routes/DealPage"));
const Vehicle = React.lazy(() => import("./deals/subRoutes/Vehicle"));
const Dealer = React.lazy(() => import("./deals/subRoutes/Dealer"));
const Purchase = React.lazy(() => import("./deals/subRoutes/Purchase"));
const Offering = React.lazy(() => import("./deals/subRoutes/Offering"));
const Ownership = React.lazy(() => import("./deals/subRoutes/Ownership"));
const Servicing = React.lazy(() => import("./deals/subRoutes/Servicing"));
const Contracting = React.lazy(() => import("./deals/subRoutes/Contracting"));
const Payments = React.lazy(() => import("./deals/subRoutes/Payments"));
const FinancialsInfoPage = React.lazy(() => import("./deals/subRoutes/Servicing/routes/FinancialsInfoPage"));
const DealLogPage = React.lazy(() => import("./deals/subRoutes/Servicing/routes/DealLogPage"));
const ServicingChecksPage = React.lazy(() => import("./deals/subRoutes/Servicing/routes/ServicingChecksPage"));
const DealerCreditDetailsPage = React.lazy(() => import("./deals/subRoutes/Servicing/routes/DealerCreditDetailsPage"));
const DealFileDetails = React.lazy(() => import("./deals/subRoutes/Files/DealFileDetails"));

const ChecksPage = React.lazy(() => import("./checks/routes/ChecksPage"));

const RiskViewPage = React.lazy(() => import("./risk/routes/RiskViewPage"));

const UserManagementPage = React.lazy(() => import("./users/routes/UserManagementPage"));
const UserDetailsPage = React.lazy(() => import("./users/routes/UserPage"));
const UserDetails = React.lazy(() => import("./users/UserDetails"));
const EditUser = React.lazy(() => import("./users/EditUser"));
const OnboardingUsersPage = React.lazy(() => import("./externalUsersOnboarding/routes/OnboardingUsersPage"));
const ExternalUsersPage = React.lazy(() => import("./externalUsers/routes/ExternalUsersPage"));
const ExternalUserPage = React.lazy(() => import("./externalUsers/routes/ExternalUserPage"));
const ExternalUserDetails = React.lazy(() => import("./externalUsers/externalUser/ExternalUserDetails"));
const EditExternalUser = React.lazy(() => import("./externalUsers/externalUser/EditExternalUser"));
const IdentityVerificationListing = React.lazy(
  () => import("./externalUsersOnboarding/identityVerification/IdentityVerificationListing")
);
const IdentityVerificationDetails = React.lazy(
  () => import("./externalUsersOnboarding/identityVerification/IdentityVerificationDetails")
);

const KYCListingPage = React.lazy(() => import("./kyc/routes/KYCListingPage"));
const KYCApplicationPage = React.lazy(() => import("./kyc/routes/KYCApplicationPage"));
const MyAccountPage = React.lazy(() => import("./account/routes/MyAccountPage"));
const VehiclePage = React.lazy(() => import("./vehicle/routes/VehiclePage"));
const VehiclesPage = React.lazy(() => import("./vehicle/routes/VehiclesPage"));
const VehicleDeals = React.lazy(() => import("./vehicle/routes/VehicleDeals"));
const CreateVehiclePage = React.lazy(() => import("./vehicle/routes/CreateVehiclePage"));

const Information = React.lazy(() => import("./vehicle/subRoutes/Information"));
const Files = React.lazy(() => import("./vehicle/subRoutes/Files"));
const FileDetails = React.lazy(() => import("./vehicle/subRoutes/FileDetails"));
const EditVehicle = React.lazy(() => import("./vehicle/subRoutes/EditVehicle"));

const ContactsPage = React.lazy(() => import("./contacts/routes/ContactsPage"));
const ContactPage = React.lazy(() => import("./contacts/routes/ContactPage"));
const AddContactPage = React.lazy(() => import("./contacts/routes/AddContactPage"));
const ContactDetails = React.lazy(() => import("./contacts/subRoutes/ContactDetails"));
const ContactFiles = React.lazy(() => import("./contacts/subRoutes/ContactFiles"));
const ContactFileDetails = React.lazy(() => import("./contacts/subRoutes/ContactFileDetails"));
const ContactRelations = React.lazy(() => import("./contacts/subRoutes/ContactRelations"));
const EditContact = React.lazy(() => import("./contacts/subRoutes/EditContact"));

const GTXIntegrationPage = React.lazy(() => import("./integrations/gtx/routes/GTXIntegrationPage"));
const VinDecoderPage = React.lazy(() => import("./integrations/vinDecoder/routes/VinDecoderPage"));
const FragusDecoderPage = React.lazy(() => import("./integrations/fragus/routes/FragusDecoderPage"));
const CarInfoDecoderPage = React.lazy(() => import("./integrations/carInfo/routes/CarInfoDecoderPage"));
const ArosPage = React.lazy(() => import("./integrations/aros/routes/ArosPage"));
const ArosInvoicesPage = React.lazy(() => import("./integrations/aros/routes/ArosInvoicesPage"));
const ArosOrganisationsPage = React.lazy(() => import("./integrations/aros/routes/ArosOrganisationsPage"));

const FilesPage = React.lazy(() => import("./files/routes/FilesPage"));
const FileDetailsPage = React.lazy(() => import("./files/routes/FileDetailsPage"));
const ScheduledAuditsPage = React.lazy(() => import("./audits/routes/ScheduledAuditsPage"));
const ScheduledAuditPage = React.lazy(() => import("./audits/routes/ScheduledAuditPage"));
const AuditedVehiclePage = React.lazy(() => import("./audits/routes/AuditedVehiclePage"));
const SiteAuditsPage = React.lazy(() => import("./audits/routes/SiteAuditsPage"));
const SiteAuditPage = React.lazy(() => import("./audits/routes/SiteAuditPage"));
const VehicleAuditDetailsPage = React.lazy(() => import("./audits/routes/VehicleAuditDetailsPage"));
const EventLogsPage = React.lazy(() => import("./domain-events/routes/EventLogsPage"));

const DealerAccountsPage = React.lazy(() => import("./dealerAccounts/routes/DealerAccountsPage"));
const DealerAccountPage = React.lazy(() => import("./dealerAccounts/routes/DealerAccountPage"));
const AddDealerAccountPage = React.lazy(() => import("./dealerAccounts/routes/AddDealerAccountPage"));
const DealerAccountDetails = React.lazy(() => import("./dealerAccounts/subRoutes/DealerAccountDetails"));
const EditDealerAccount = React.lazy(() => import("./dealerAccounts/subRoutes/EditDealerAccount"));
const DealerAccountDeals = React.lazy(() => import("./dealerAccounts/subRoutes/DealerAccountDeals"));
const DealerAccountFiles = React.lazy(() => import("./dealerAccounts/subRoutes/DealerAccountFiles"));
const DealerAccountFileDetails = React.lazy(() => import("./dealerAccounts/subRoutes/DealerAccountFileDetails"));
const DealerAccountSiteAudits = React.lazy(() => import("./dealerAccounts/subRoutes/DealerAccountSiteAudits"));
const DealerAccountLocations = React.lazy(() => import("./dealerAccounts/subRoutes/DealerAccountLocations"));
const DealerAccountRequiredActions = React.lazy(
  () => import("./dealerAccounts/subRoutes/DealerAccountRequiredActions")
);

const DealerAccountLocationDetails = React.lazy(
  () => import("./dealerAccounts/subRoutes/DealerAccountLocationDetails")
);

const RepossessionsPage = React.lazy(() => import("./repossessions/routes/RepossessionsPage"));
const RepossessionPage = React.lazy(() => import("./repossessions/routes/RepossessionPage"));
const EditRepossessionPage = React.lazy(() => import("./repossessions/routes/EditRepossessionPage"));
const AlertsPage = React.lazy(() => import("./alerts/routes/AlertsPage"));

const ObservationsPage = React.lazy(() => import("./monitoring/observations/routes/ObservationsPage"));
const SchedulesPage = React.lazy(() => import("./monitoring/schedules/routes/SchedulesPage"));
const ObservationPage = React.lazy(() => import("./monitoring/observations/routes/ObservationPage"));

const DepositsPage = React.lazy(() => import("./deposits/routes/DepositsPage"));
const DepositPage = React.lazy(() => import("./deposits/routes/DepositPage"));

const IncomingPaymentsPage = React.lazy(() => import("./payments/routes/IncomingPayments"));

const CustomerInvoicesPage = React.lazy(() => import("./customerInvoices/routes/CustomerInvoicesPage"));
const CustomerInvoicePage = React.lazy(() => import("./customerInvoices/routes/CustomerInvoicePage"));

const CreateDepositPage = React.lazy(() => import("./depositPayment/routes/CreateDepositPage"));
const CreateDepositSuccessPage = React.lazy(() => import("./depositPayment/routes/CreateDepositSuccessPage"));
const CreateDepositFailurePage = React.lazy(() => import("./depositPayment/routes/CreateDepositFailurePage"));
const CreateDepositClosePage = React.lazy(() => import("./depositPayment/routes/CreateDepositClosePage"));

const WithdrawalSuccessPage = React.lazy(() => import("./withdrawal/routes/WithdrawalSuccessPage"));
const WithdrawalFailurePage = React.lazy(() => import("./withdrawal/routes/WithdrawalFailurePage"));
const WithdrawalClosePage = React.lazy(() => import("./withdrawal/routes/WithdrawalClosePage"));
const WithdrawalPage = React.lazy(() => import("./withdrawal/routes/WithdrawalPage"));

const CreditsPage = React.lazy(() => import("./credit/routes/CreditsPage"));
const DealerCreditPage = React.lazy(() => import("./credit/routes/DealerCreditPage"));
const LoanSimulationPage = React.lazy(() => import("./credit/routes/LoanSimulationPage"));
const DealerCredit = React.lazy(() => import("./credit/subRoutes/DealerCredit"));

const LedgerAccountsPage = React.lazy(() => import("./ledger/routes/LedgerAccountsPage"));
const LedgerTransactionsPage = React.lazy(() => import("./ledger/routes/LedgerTransactionsPage"));
const LedgerAccountPlanPage = React.lazy(() => import("./ledger/routes/LedgerAccountPlanPage"));
const LedgerAccountDetailsPage = React.lazy(() => import("./ledger/routes/LedgerAccountDetailsPage"));
const LedgerTransactionDetailsPage = React.lazy(() => import("./ledger/routes/LedgerTransactionDetailsPage"));

const PricingRulesPage = React.lazy(() => import("./pricing/routes/PricingRulesPage"));
const PricingRulePage = React.lazy(() => import("./pricing/routes/PricingRulePage"));
const PricingRuleRevisionPage = React.lazy(() => import("./pricing/routes/PricingRuleRevisionPage"));
const NewPricingRule = React.lazy(() => import("./pricing/routes/NewPricingRule"));
const ClonePricingRule = React.lazy(() => import("./pricing/routes/ClonePricingRule"));

const TraficomPage = React.lazy(() => import("./integrations/traficom/routes/TraficomPage"));
const LoansPage = React.lazy(() => import("./loans/routes/LoansPage"));
const LoanPage = React.lazy(() => import("./loans/routes/LoanPage"));

const SystemMessagesPage = React.lazy(() => import("./systemMessages/routes/SystemMessagesPage"));
const SystemMessagePage = React.lazy(() => import("./systemMessages/routes/SystemMessagePage"));
const EditSystemMessagePage = React.lazy(() => import("./systemMessages/routes/EditSystemMessagePage"));

const VehicleMakesPage = React.lazy(() => import("./vehicleMakeModel/routes/VehicleMakesPage"));
const VehicleMakeDetailsPage = React.lazy(() => import("./vehicleMakeModel/routes/VehicleMakeDetailsPage"));
const EditVehicleMakePage = React.lazy(() => import("./vehicleMakeModel/routes/EditVehicleMakePage"));
const VehicleModelDetailsPage = React.lazy(() => import("./vehicleMakeModel/routes/VehicleModelDetailsPage"));
const EditVehicleModelPage = React.lazy(() => import("./vehicleMakeModel/routes/EditVehicleModelPage"));

use(initReactI18next).init({
  resources: resources,
  lng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false,
  },
});

const getCurrency = (hfTenantKey: HF_tenant_key) => {
  let currency;
  if (hfTenantKey === "hf_fi") {
    currency = "EUR";
  } else {
    currency = "SEK";
  }
  return currency;
};

function App() {
  let location = useLocation();

  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { duration: 5000, isClosable: true } }}>
      <Fonts />
      <Routes location={location}>
        <Route path="/" element={<SignInPage />} />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/callback" element={<AuthCallbackPage />} />
        <Route path="/logout" element={<Navigate to="/" />} />
        <Route
          path="/set-password"
          element={
            <Suspense>
              <PasswordResetPage />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedComponent>
              <Layout>
                <Suspense>
                  <Outlet />
                </Suspense>
              </Layout>
            </ProtectedComponent>
          }
        >
          <Route path="/create-user" element={<CreateUserPage />} />
          <Route path="/send-email" element={<SendEmailPage />} />
          <Route path="/emails" element={<EmailsPage />} />
          <Route path="/email/:email_id" element={<EmailDetailsPage />} />

          <Route path="/dashboard" element={<DashboardPage />} />

          <Route path="/deals" element={<DealsPage />} />
          <Route path="/deal/:deal_id" element={<DealPage />}>
            <Route index element={<Vehicle />} />
            <Route path="vehicle" element={<Vehicle />} />
            <Route path="dealer" element={<Dealer />} />
            <Route path="purchase" element={<Purchase />} />
            <Route path="purchase/file/:file_id/" element={<DealFileDetails />} />
            <Route path="offering" element={<Offering />} />
            <Route path="contracting" element={<Contracting />} />
            <Route path="contracting/file/:file_id/" element={<DealFileDetails />} />
            <Route path="payments" element={<Payments />} />
            <Route path="payments/file/:file_id/" element={<DealFileDetails />} />
            <Route path="ownership" element={<Ownership />} />
            <Route path="servicing" element={<Servicing />}>
              <Route index element={<FinancialsInfoPage />} />
              <Route path="financials" element={<FinancialsInfoPage />} />
              <Route path="deal-log" element={<DealLogPage />} />
              <Route path="checks" element={<ServicingChecksPage />} />
              <Route path="dealer-credit/:credit_id" element={<DealerCreditDetailsPage />} />
            </Route>
            <Route path="file/:file_id/" element={<DealFileDetails />} />
          </Route>
          <Route path="/checks" element={<ChecksPage />} />

          <Route path="/risk-view" element={<RiskViewPage />} />

          <Route path="/user-management" element={<UserManagementPage />} />

          <Route path="/user-management/:userId" element={<UserDetailsPage />}>
            <Route index element={<UserDetails />} />
            <Route path="edit" element={<EditUser />} />
          </Route>

          <Route path="/onboarding-users" element={<OnboardingUsersPage />} />

          <Route path="/external-users" element={<ExternalUsersPage />} />

          <Route path="/external-user/:userId" element={<ExternalUserPage />}>
            <Route index element={<ExternalUserDetails />} />
            <Route path="edit" element={<EditExternalUser />} />
            <Route path="identity-verification" element={<IdentityVerificationListing />} />
            <Route path="identity-verification/:process_id" element={<IdentityVerificationDetails />} />
          </Route>

          <Route path="/kyc" element={<KYCListingPage />} />
          <Route path="/kyc/:kycApplicationId" element={<KYCApplicationPage />} />

          <Route path="/my-account" element={<MyAccountPage />} />
          <Route path="/vehicles" element={<VehiclesPage />} />

          <Route path="/vehicle/:vehicle_id" element={<VehiclePage />}>
            <Route index element={<Information />} />
            <Route path="edit" element={<EditVehicle />} />
            <Route path="files" element={<Files />} />
            <Route path="file/:file_id/" element={<FileDetails />} />
            <Route path="deals" element={<VehicleDeals />} />
          </Route>
          <Route path="/create-vehicle" element={<CreateVehiclePage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/contact/add" element={<AddContactPage />} />
          <Route path="/contact/:contact_id" element={<ContactPage />}>
            <Route index element={<ContactDetails />} />
            <Route path="edit" element={<EditContact />} />
            <Route path="relations" element={<ContactRelations />} />
            <Route path="files" element={<ContactFiles />} />
            <Route path="file/:file_id/" element={<ContactFileDetails />} />
          </Route>

          <Route path="/integrations/gtx" element={<GTXIntegrationPage />} />
          <Route path="/integrations/vin-decoder" element={<VinDecoderPage />} />
          <Route path="/integrations/fragus" element={<FragusDecoderPage />} />
          <Route path="/integrations/car-info" element={<CarInfoDecoderPage />} />
          <Route path="/integrations/aros" element={<ArosPage />}>
            <Route index element={<ArosInvoicesPage />} />
            <Route path="organisations" element={<ArosOrganisationsPage />} />
          </Route>
          <Route path="/files" element={<FilesPage />} />
          <Route path="/file/:file_id" element={<FileDetailsPage />} />
          <Route path="/site-audits" element={<SiteAuditsPage />} />
          <Route path="/site-audit/:audit_id" element={<SiteAuditPage />} />
          <Route path="/site-audit/:audit_id/vehicle/:vehicle_id" element={<VehicleAuditDetailsPage />} />
          <Route path="/site-audit/:vehicle_id/file/:file_id" element={<VehicleAuditDetailsPage />} />
          <Route path="/scheduled-audits" element={<ScheduledAuditsPage />} />
          <Route path="/scheduled-audit/:audit_id/" element={<ScheduledAuditPage />} />
          <Route path="/scheduled-audit/:audit_id/vehicle/:vehicle_id/" element={<AuditedVehiclePage />} />
          <Route path="/event-logs" element={<EventLogsPage />} />
          <Route path="/dealer-accounts" element={<DealerAccountsPage />} />
          <Route path="/dealer-account/add" element={<AddDealerAccountPage />} />
          <Route path="/dealer-account/:account_id" element={<DealerAccountPage />}>
            <Route index element={<DealerAccountDetails />} />
            <Route path="edit" element={<EditDealerAccount />} />
            <Route path="deals" element={<DealerAccountDeals />} />
            <Route path="site-audits" element={<DealerAccountSiteAudits />} />
            <Route path="files" element={<DealerAccountFiles />} />
            <Route path="file/:file_id/" element={<DealerAccountFileDetails />} />
            <Route path="locations" element={<DealerAccountLocations />} />
            <Route path="location/:location_id" element={<DealerAccountLocationDetails />} />
            <Route path="required-actions" element={<DealerAccountRequiredActions />} />
            <Route path="notifications" element={<DealerAccountNotifications />} />
          </Route>

          <Route path="/repossessions" element={<RepossessionsPage />} />
          <Route path="/repossession/:repossession_id" element={<RepossessionPage />} />
          <Route path="/repossession/:repossession_id/edit" element={<EditRepossessionPage />} />
          <Route path="/loans" element={<LoansPage />} />
          <Route path="/loan/:applicationId" element={<LoanPage />} />

          <Route path="/alerts" element={<AlertsPage />} />
          <Route path="/monitoring/observations" element={<ObservationsPage />} />
          <Route path="/monitoring/observations/:observation_id" element={<ObservationPage />} />
          <Route path="/monitoring/schedules" element={<SchedulesPage />} />
          <Route path="/deposits" element={<DepositsPage />} />
          <Route path="/deposit/:deposit_id" element={<DepositPage />} />
          <Route path="/payments" element={<IncomingPaymentsPage />} />
          <Route path="/invoices" element={<CustomerInvoicesPage />} />
          <Route path="/invoice/:invoice_id" element={<CustomerInvoicePage />} />

          <Route path="/credits" element={<CreditsPage />} />
          <Route path="/dealer-credit/:credit_id" element={<DealerCreditPage />}>
            <Route index element={<DealerCredit />} />
          </Route>

          <Route path="/loan-simulation" element={<LoanSimulationPage />} />

          <Route path="ledger-accounts" element={<LedgerAccountsPage />} />
          <Route path="ledger-transactions" element={<LedgerTransactionsPage />} />
          <Route path="ledger-transaction/:transaction_id" element={<LedgerTransactionDetailsPage />} />
          <Route path="ledger-account-plan" element={<LedgerAccountPlanPage />} />
          <Route path="ledger-account/:account_id" element={<LedgerAccountDetailsPage />} />

          <Route path="price-rules" element={<PricingRulesPage />} />
          <Route path="price-rule/:rule_id" element={<PricingRulePage />} />
          <Route path="price-rule-revision/:rule_id/:revision_id" element={<PricingRuleRevisionPage />} />
          <Route path="price-rule/new" element={<NewPricingRule />} />
          <Route path="price-rule-clone/:rule_id" element={<ClonePricingRule />} />

          <Route path="traficom" element={<TraficomPage />} />

          <Route path="/system-messages" element={<SystemMessagesPage />} />
          <Route path="/system-message/:message_id" element={<SystemMessagePage />} />
          <Route path="/system-message/:message_id/edit" element={<EditSystemMessagePage />} />
          <Route path="/system-message/add" element={<CreateSystemMessagePage />} />

          <Route path="/vehicle-makes" element={<VehicleMakesPage />} />
          <Route path="/vehicle-make/:vehicle_make_id" element={<VehicleMakeDetailsPage />} />
          <Route path="/vehicle-make/:vehicle_make_id/edit" element={<EditVehicleMakePage />} />

          <Route path="/vehicle-model/:vehicle_model_id" element={<VehicleModelDetailsPage />} />
          <Route path="/vehicle-model/:vehicle_model_id/edit" element={<EditVehicleModelPage />} />
        </Route>
        <Route
          path="/withdrawal"
          element={
            <Suspense>
              <Outlet />
            </Suspense>
          }
        >
          <Route path="/withdrawal" element={<WithdrawalPage />} />
          <Route path="/withdrawal/success" element={<WithdrawalSuccessPage />} />
          <Route path="/withdrawal/failure" element={<WithdrawalFailurePage />} />
          <Route path="/withdrawal/close" element={<WithdrawalClosePage />} />
        </Route>
        <Route
          path="/create-deposit"
          element={
            <Suspense>
              <Outlet />
            </Suspense>
          }
        >
          <Route path="/create-deposit" element={<CreateDepositPage />} />
          <Route path="/create-deposit/success" element={<CreateDepositSuccessPage />} />
          <Route path="/create-deposit/failure" element={<CreateDepositFailurePage />} />
          <Route path="/create-deposit/close" element={<CreateDepositClosePage />} />
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

export default App;
export { getCurrency };
