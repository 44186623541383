export const translations = {
  logout: "Logout",
  no_results: "No results",
  no_items: "No items",
  error_warning: "Something went wrong",
  fetch_error_message: "Something went wrong while fetching data",
  hf_tenant_key: {
    hf_fi: "HF FI",
    hf_se: "HF SE",
    country_code: {
      hf_fi: "FI",
      hf_se: "SE",
    },
    country: {
      hf_fi: "Finland",
      hf_se: "Sweden",
    },
  },
  placeholders: {
    type_of_boat: "Type of boat",
    confirm: "Confirm",
    countries: "Countries",
    selected: "Selected",
    user: "User",
    users: "Users",
    city: "City",
    postal_code: "Postal code",
    phone: "Phone",
    email: "name@domain.com",
    full_name: "Joe Doe",
    first_name: "First name",
    last_name: "Last name",
    search_back_office: "Search the back office",
    search_page: "Search page",
    email_subject: "Email subject",
    subject: "Subject",
    sender: "sender",
    recipients: "recipient1, recipient2, recipient3, ...",
    filter_by: "Filter table by name, email or phone number",
    select: "Select",
    reg_number: "Reg. number",
    filter_table: "Filter table",
    search_dealer: "Search dealer",
    registration_number: "Registration number",
    action_for_selected_items: "Action for selected items",
    cc: "CC email addresses",
    bcc: "BCC email addresses",
    invoice_id: "Invoice ID",
    customer_id: "Customer ID",
    dealer: "Dealer",
    contact: "Contact",
    vehicle: "Vehicle",
    location: "Location",
    status: "Status",
    notes: "Notes",
    note: "Note",
    photos: "Photos",
    website: "Website",
    search: "Search",
    cancel: "Cancel",
    edit: "Edit",
    search_for_dealer: "Dealers filter",
    add: "Add",
    add_new: "Add new",
    delete: "Delete",
    save: "Save",
    remove: "Remove",
    done: "Done",
    update: "Update",
    dealer_id: "Dealer id",
    hf_tenant_key: "HF tenant key",
    location_id: "Location id",
    name: "Name",
    created_after: "Created after",
    created_before: "Created before",
    created_at: "Created at",
    created_by: "Created by",
    updated_at: "Updated at",
    logged_by: "Logged by",
    type: "Type",
    seller: "Seller",
    offering: "Offering",
    purchase: "Purchase",
    advance: "Advance",
    payout: "Payout",
    ownership: "Ownership",
    id: "ID",
    address: "Address",
    labels: "Labels",
    label: "Label",
    action: "Action",
    account: "Account",
    account_number: "Account number",
    clearing_number: "Clearing number",
    bank_account: "Bank account",
    operators: "Operators",
    preview: "Preview",
    transfer: "Transfer",
    recipient: "Recipient",
    amount: "Amount",
    initial_principal: "Initial principal",
    invoice_type: "Invoice type",
    file: "File",
    files: "Files",
    iban: "IBAN",
    swift: "SWIFT",
    bic: "BIC",
    payment_ref: "Payment ref",
    dismiss: "Dismiss",
    entity: "Entity",
    market: "Market",
    yes: "Yes",
    no: "No",
    alert: "Alert",
    alerts: "Alerts",
    deal: "Deal",
    deals: "Deals",
    for: "for",
    date: "Date",
    value: "Value",
    state: "State",
    balance: "Balance",
    payment_prefix: "Payment prefix",
    filename: "Filename",
    dev_mode: "Dev mode",
    local_mode: "Local mode",
    request: "Request",
    role: "Role",
    owner: "Owner",
    ssn: "SSN",
    company: "Company",
    org_number: "Org. number",
    post_address: "Post address",
    postal_code_city: "Postal code, city",
    phone_no: "Phone number",
    copy: "Copy",
    close: "Close",
    export: "Export",
    avg_inventory_days: "Avg. inventory days",
    total_deals_made: "Total deals made",
    financier: "Financier",
    due_date: "Due date",
    due_in: "Due in",
    status_at: "Status at",
    none: "-",
    last_updated: "Last updated",
    start_date: "Start date",
    end_date: "End date",
    vat_rate: "VAT rate",
    from: "From",
    to: "To",
    currency: "Currency",
    search_country: "Search country",
    user_account: "User account",
    export_to_csv: "Export to CSV",
    export_as_csv: "Export as CSV",
    export_csv_info:
      "Exports usually take about 30 minutes to compile. We will send you an email when the file is ready.",
    export_successfully_requested: "Export successfully requested",
    export_error: "Export could not be requested",
    description: "Description",
    sms: "SMS",
    path: "Path",
    enabled: "Enabled",
    disabled: "Disabled",
    actions: "Actions",
    send_to: "Send to",
    send_back_to: "Send back to",
    primary: "Primary",
    pts: "pts.",
    temp_na: "N/A",
    history: "History",
  },
  system_actor_types: {
    internal_user: "Internal user",
    external_user: "External user",
    system: "System",
  },
  tags: {
    internal_user: "Internal user",
    external_user: "External user",
    vehicle: "Vehicle",
    file: "File",
    legal_entity: "Contact",
    dealer_credit: "Dealer credit",
    dealer_account: "Dealer account",
    deal: "Deal",
    vehicle_site_audit: "Vehicle site audit",
    kyc_application: "KYC application",
    outbound_email: "Outbound email",
    dealer_location: "Dealer location",
    monitoring_schedule: "Monitoring schedule",
    vehicle_valuation: "Vehicle valuation",
    signing_process: "Signing process",
    payout_task: "Payout task",
    invoice: "Invoice",
    state_assertion: "State assertion",
    see_credit: "See credit",
  },
  account: {
    success_message: "Your account details have been saved",
    error_message: "Something went wrong, please try again",
    select_tenant_error_message: "Something went wrong while changing tenant",
    title: "My Account",
    email: "Email",
    full_name: "Full name",
    phone: "Phone",
    save_btn: "Save information",
  },
  admin: {
    create_user: "Create new user",
    create_user_title: "Create user form",
    error_message: "User already exists",
    email: "Email",
    full_name: "User's full name",
    user_role: "User role",
    save_btn: "Create new user",
    modal_trigger: "Create user",
  },
  password_reset: {
    wait: "Please wait...",
    invalid_invitation: "Your invitation link is invalid",
    contact: "For any questions, please get in touch with our colleagues at",
  },
  dashboard: {
    title: "Dashboard",
    error_message: "Something went wrong",
    create_user: "Create user",
    send_email: "Send email",
    my_account: "My account",
    user_management: "User management",
    welcome: "Welcome to",
    backOffice: "Handlarfinans Back Office",
    loggedInAs: "You are logged in as",
  },
  events: {
    credit_camel_case: "Credit",
    roles_revoked: "Roles revoked:",
    roles_granted: "Roles granted:",
    roles_for_external_user: "Roles for external user",
    domain_alert_deleted_by: "A domain alert was deleted by",
    vehicle_repossession_camel_case: "Vehicle repossession",
    vehicle_repossession: "vehicle repossession",
    consumer_credit_application_camel_case: "Consumer credit application",
    was_cancelled_by: "was cancelled by",
    was_activated: "was activated",
    was_rejected_by: "was rejected by",
    kyc_application_metadata: "metadata",
    was_onboarded: "was onboarded",
    domain_alert_updated_by: "A domain alert was updated by",
    domain_alert_created: "A domain alert was created by",
    credit: "credit",
    for: "for",
    tenant_access: "tenant access",
    dealer_camel_case: "Dealer",
    inventory_vehicle: "Inventory vehicle",
    was_reopened_by: "was reopened by",
    was_submitted_by: "was submitted by",
    kyc_application: "KYC application",
    consumer_credit_application: "consumer credit application",
    new_leasing_notification: "A new leasing notification for",
    was_issued_by: "was issued by",
    pending: "pending",
    to_date: "To date",
    from_date: "From date",
    all_event_types: "All event types",
    changed_tenant_access: "changed tenant access to",
    new_contract_was_created: "A new contract was created by",
    system: "system",
    external_user: "External user",
    was_transfer_to_hf: "was transferred to Handlarfinans",
    from: "from",
    was_transfer_to_dealer: "was transferred to dealer",
    was_delivered_to_servicer: "was delivered to servicer",
    new_state_assertion_was_created: "A new state assertion was created by system",
    was_approved_by: "was approved by",
    was_moved_in_servicing_by: "was moved in servicing by",
    was_settled_by: "was settled by",
    offer_was_invalidated_by: "offer was invalidated by",
    offer_was_requested_by: "offer was requested by",
    offer_was_published_by: "offer was published by",
    offer_was_accepted_by: "offer was accepted by",
    deposit: "deposit",
    invoice: "Invoice",
    event: "Event",
    events: "Events",
    vehicle: "vehicle",
    vehicle_camel_case: "Vehicle",
    internal: "Internal",
    user: "user",
    location: "activated from IP {{remote_ip}} with UserAgent: {{user_agent}}",
    new: "A new",
    a: "A",
    internal_user: "internal user",
    by: "by",
    was_created: "was created",
    was_created_by: "was created by",
    was_deleted: "was deleted by",
    was_updated_by: "was updated by",
    was_updated: "was updated",
    was_voided_by: "was voided by",
    was_processed_by: "was processed by",
    created_by: "created by",
    deleted_by: "deleted by",
    updated_by: "updated by",
    internal_user_camel_case: "Internal user",
    internal_user_invited: "Internal User Invited",
    note_related: "A note related with",
    email_delivered: "Outbound email with id {{email_id}} delivered",
    email_deliver_failed: "Outbound email failed to deliver",
    email_was_sent: "Outbound email was sent",
    total_count: "Event log ({{total_count}})",
    load_more: "Load more",
    finance_report: "finance report",
    related_with: "related with",
    dealer_location: "A dealer location",
    file: "file",
    file_camel_case: "File",
    contact: "contact",
    contact_camel_case: "Contact",
    dealer_account: "dealer account",
    dealer_account_camel_case: "Dealer account",
    deal: "deal",
    deal_camel_case: "Deal",
    deal_sections: {
      dealer_account: "dealer account",
      data_room: "data room",
    },
    audit_for: "Audit for",
    new_audit_for: "A new audit for",
    new_payout_task: "A new payout task",
  },
  event_logs: {
    no_logs: "No event logs available",
    no_tags: "No tags available",
  },
  emails: {
    files: "Files",
    send_email: "Send email",
    compose: "Compose",
    error_message: "Something went wrong while previewing the selected email template",
    success_message: "The email was successfully sent",
    error_message_try_again: "Something went wrong, please try again",
    email_subject: "Email subject",
    email_template_id: "Email template id",
    email_template_id_placeholder: "Select an email template",
    sender: "Sender",
    recipients: "Recipients",
    send_btn: "Send email",
    cc: "CC",
    bcc: "BCC",
    template_variables: "Template variables",
    attachments: "Attachments",
    body: "Body",
    tags: "Tags",
    send_to: "Send to",
    subject: "Subject",
    emails_title: "Emails",
    no_emails: "No emails",
    email_status_options: {
      initiated: "Initiated",
      delivered: "Delivered",
      rejected: "Rejected",
      failed: "Failed",
    },
    email_title: "Email details",
    email_preview: "Email preview",
  },
  notes: {
    add_note: "Add a note",
    successfully_deleted: "Note successfully deleted",
    delete_error: "Note could not be deleted",
    delete_note: "Delete note",
    delete_warning: "You are about to delete this note. Are you sure?",
    cancel: "Cancel",
    delete: "Delete",
    edit: "Edit",
    edit_note: "Edit note",
    load_more: "Load more",
    internal_user: "Internal user",
    no_notes: "No notes found yet. Add your own.",
    internal_notes: "Internal notes",
  },
  sidebar: {
    consumer_loans: "Consumer loans",
    users: "Users",
    internal_users: "Internal users",
    external_users: "External users",
    contacts: "Contacts",
    client_management: "Client management",
    vehicles: "Vehicles",
    vehicle_makes: "Manufacturers",
    payment_and_invoices: "Payment & invoices",
    integrations: "Integrations",
    vin_decoder: "VIN decoder",
    GTX: "GTX",
    aros: "Aros",
    fragus: "Fragus",
    carinfo: "Carinfo",
    traficom: "Traficom",
    purchases: "Purchases",
    active: "Active",
    audits: "Audits",
    site_audits: "Site audits",
    scheduled_audits: "Scheduled audits",
    event_logs: "Event logs",
    alerts: "Alerts",
    monitoring: "Monitoring",
    monitoring_observations: "Observations",
    monitoring_schedules: "Schedules",
    deals: "Deals",
    checks: "Checks",
    dealer_accounts: "Dealer accounts",
    deposits: "Deposits",
    incoming_payments: "Incoming payments",
    customer_invoices: "Customer invoices",
    kyc: "KYC",
    credits: "Credits",
    credit: "Credit",
    loan_simulation: "Loan simulation",
    ledger: "Ledger",
    ledger_account_plan: "Account plan",
    ledger_accounts: "Accounts",
    ledger_transactions: "Transactions",
    files: "Files",
    repossessions: "Repossessions",
    onboarding_users: "Onboarding users",
    risk_view: "Risk view",
    emails: "Emails",
    system_messages: "System messages",
    price_rules: "Price rules",
  },
  topbar: {
    my_account: "My account",
    logout: "Logout",
  },
  users: {
    name: "Name",
    added_on: "Added on",
    account_info: "Account info",
    edit_account_info: "Edit account info",
    edit_success_message: "User was successfully edited",
    success_message: "User was successfully deleted",
    error_message: "Unable to delete the user",
    edit_user: "Edit user",
    send_email: "Send email",
    delete: "Delete",
    error_warning: "Something went wrong",
    no_user: "There are no users",
    email: "Email",
    full_name: "Full name",
    phone: "Phone",
    user_roles: "User roles",
    user_tenants: "User tenants",
    user_tenants_error: "You must select at least one tenant",
    mandatory_fields: "All fields are mandatory",
    save_btn: "Save information",
    user_management: "User management",
    edit_users: "Edit",
    phone_number: "Phone number",
    status: "Status",
    delete_item: "Delete user",
    delete_warning: "You are about to delete {{name}}. Are you sure?",
    cancel: "Cancel",
  },
  external_users: {
    error_warning: "Something went wrong",
    external_users: "External users",
    create_user: "Create external user",
    create_new_user: "Create new user",
    added_on: "Added on",
    name: "Name",
    email: "Email",
    status: "Status",
    state: "State",
    contact: "Contact",
    dealer: "Dealer",
    mandatory_fields: "All fields are mandatory",
    edit_user: "Edit user",
    edit: "Edit",
    send_email: "Send email",
    delete: "Delete",
    delete_item: "Delete external user",
    delete_warning: "You are about to delete {{name}}. Are you sure?",
    delete_warning_no_name: "You are about to delete this external user. Are you sure?",
    delete_message_error: "External user could not be deleted",
    delete_message_success: "External user was successfully deleted",
    filter_by: "Filter table by name or email",
    save_btn: "Save information",
    mandatory_name: "Name is mandatory",
    mandatory_role: "Choose at least one role",
    edit_success_message: "External user was successfully edited",
    edit_error_message: "External user could not be edited",
    connect_to_dealer: "Connect to dealer",
    connect_to_dealer_success: "External user was successfully connected to dealer",
    connect_to_dealer_error: "External user could not be connected to dealer",
    onboarding_users: "Onboarding users",
    identity_verification: "Identity verification",
    identity_verification_files: "Identity verification files",
    identity_verification_state_options: {
      initiated: "Initiated",
      verified: "Verified",
      rejected: "Rejected",
    },
    identity_verification_type_options: {
      manual: "Manual",
      bankid: "BankID",
    },
    no_processes: "No processes",
    external_user_id: "External user ID",
    approve: "Approve",
    reject: "Reject",
    identity_verification_delete: "Delete identity verification",
    identity_verification_delete_success: "Identity verification was successfully deleted",
    identity_verification_delete_error: "Identity verification could not be deleted",
    identity_verification_delete_warning: "You are about to delete this identity verification process. Are you sure?",
    update_state_success: "Identity verification state was successfully updated",
    update_state_error: "Identity verification state could not be updated",
    external_user_state_options: {
      new: "New",
      active: "Active",
      deleted: "Deleted",
    },
    external_user_roles: {
      owner: "Owner",
      employee: "Employee",
      accountant: "Accountant",
      prospect: "Prospect",
      manager: "Manager",
    },
  },
  vehicle: {
    vehicle_type: "Vehicle type",
    current_operator: "Current operator",
    vehicles: "Vehicles",
    reg_number: "Reg. number",
    create_date: "Created date",
    add_new: "Add new vehicle",
    info: "Vehicle information",
    error_warning: "Something went wrong",
    information: "Information",
    purchase: "Purchase",
    files: "Files",
    purchase_page: "Purchase page",
    successfully_deleted: "Vehicle was successfully deleted",
    delete_error: "Unable to delete the vehicle",
    delete: "Delete",
    successfully_edited: "The vehicle was successfully edited",
    edit_error_warning: "Something went wrong while editing the vehicle",
    successfully_created: "The vehicle was successfully created",
    create_error_warning: "Something went wrong while creating the vehicle",
    current_registration: "Current registration",
    reg_no_desc: "Reg. No. / Desc.",
    vin_win: "VIN / WIN",
    VIN: "VIN",
    make: "Make",
    model: "Model",
    model_year: "Model year",
    fuel_type: "Fuel type",
    fuel_types: {
      petrol: "Petrol",
      diesel: "Diesel",
      electric: "Electric",
      gas: "Gas",
      hybrid: "Hybrid",
      e85: "E85",
      other: "Other",
    },
    drive_type: "Drive type",
    drive_types: {
      FWD: "FWD",
      RWD: "RWD",
      AWD: "AWD",
    },
    transmission: "Transmission",
    transmission_types: {
      manual: "Manual",
      automatic: "Automatic",
      other: "Other",
    },
    milage: "Milage",
    power: "Power",
    VAT_status: "VAT Status",
    VAT_status_undefined: "VAT status undefined",
    deductible: "VAT Deductible",
    margin_scheme: "Margin scheme",
    current_owner: "Current owner",
    first_registration_date: "First registration date",
    location: "Location",
    valuation: "Valuation",
    EUR: "EUR",
    SEK: "SEK",
    save_info_btn: "Save vehicle information",
    add_new_btn: "Add new vehicle",
    raw_data: "Vehicle raw data",
    close: "Close",
    delete_vehicles: "Delete vehicles",
    delete_warning: "You are about to delete this vehicle. Are you sure?",
    cancel: "Cancel",
    edit: "Edit",
    import: "Import",
    no_vehicle: "There are no vehicles",
    no_vehicle_added: "No vehicle added",
    preview: {
      VIN: "VIN:",
      make: "Make:",
      model: "Model:",
      model_year: "Model year:",
      fuel_type: "Fuel type:",
      transmission: "Transmission:",
      drive_type: "Drive type:",
      power_kw: "Power kw:",
      torque_nm: "Torque nm:",
      mileage_km: "Mileage km:",
      owner_name: "Current owner name:",
      owner_type: "Current owner type:",
      owner_address: "Current owner address:",
      reg_number: "Registration number:",
      market_id: "Market ID:",
      reg_at: "Registered at:",
      notifications: "Notifications:",
      warnings: "Warnings:",
    },
    deals: "Deals",
    owner_type_placeholder: "Select owner type",
    owner_types: {
      handlarfinans: "Handlarfinans",
      contact: "Contact",
      inline: "Inline",
    },
    select_tenant_placeholder: "Select tenant key",
    search_contact_placeholder: "Search contact",
    current_valuation: "Current valuation",
    financier: "Financier",
    vehicle_types: {
      car: "Car",
      boat: "Boat",
    },
    type_of_boat: "Type of boat",
    win: "WIN",
    boat_types: {
      motor_ship: "Motor ship",
      sail_ship: "Sail ship",
      other: "Other",
    },
    engine_serial_number: "Engine serial number",
    engine_model: "Engine model",
    engine_manufacturing_year: "Engine manufacturing year",
    motor_identification: "Motor identification",
  },
  vehicle_valuation: {
    add_title: "Add vehicle valuation",
    add_valuation: "Add valuation",
    add_valuation_success: "New vehicle valuation was successfully added",
    add_valuation_error: "New vehicle valuation couldn't be added",
    amount_mandatory: "Amount field is mandatory",
    valuation: "Valuation",
    accuracy: "Accuracy",
    valuation_accuracy: {
      high: "High",
      medium: "Medium",
      low: "Low",
      very_low: "Very low",
    },
    no_valuations: "There is no valuation",
    valuated_by: "Valuated by",
    source: "Source",
    valuation_request_success: "Valuation request was successfully sent",
    valuation_request_error: "Error while sending valuation request",
    mileage_km_required: "Vehicle mileage is required, please update vehicle information!",
    no_valuation_found: "No valuation available from external source",
    valuation_copied_to_clipboard: "Valuation info copied to clipboard",
  },
  gtx: {
    vehicle_import: "GTX vehicle import",
    no_data: "No data available",
    registration_number: "Registration number",
    fetch_data: "Fetch GTX data",
    fetch_appraisal: "Fetch appraisal",
  },
  import_vehicle: {
    data_import: "Data import",
    error_message: "Couldn't fetch data",
    cancel: "Cancel",
    import: "Import",
  },
  vin_decoder: {
    page_title: "VIN decoder",
    decode_btn: "Decode",
    hide: "Hide raw data",
    show: "Show raw data",
    VIN: "VIN",
  },
  files: {
    files: "Files",
    file: "File",
    create_update_at: "Created/updated at",
    owner: "Owner",
    file_name: "File name",
    size: "Size",
    no_file: "No files",
    success_message: "File was successfully deleted",
    error_message: "Unable to delete the file",
    download: "Download",
    delete: "Delete",
    download_error: "Unable to download this file",
    upload_files: "Upload files",
    drag_drop: "Drag & drop your files here, or click to select",
    upload_failed: "- Upload failed, please try again.",
    all_files: "All files",
    replace_file: "Replace file",
    last_updated: "Last updated",
    delete_file: "Delete file",
    delete_warning: "You are about to delete {{fileName}} file. Are you sure?",
    cancel: "Cancel",
    files_page: "files",
    preview: "Preview",
    was_uploaded: " was uploaded by ",
    deal_files: "Deal files",
    remove_file_error: "Error on removing file from deal",
    remove_file_success: "File was successfully removed from deal",
    scope: "Scope",
    set_scope: "Set scope",
    change_scope: "Change scope",
    scope_change_success: "File scope was successfully changed",
    scope_change_error: "Error on changing file scope",
    select_scope: "Select scope",
    scope_types: {
      private: "Private",
      group: "Dealer",
      public: "Public",
    },
    select_owner: "Select owner",
    owner_types: {
      internal_user: "Internal user",
      external_user: "External user",
      system: "System",
    },
    select_content_type: "Select content type",
    content_types: {
      "application/pdf": "PDF",
      "text/csv": "CSV",
      "image/jpeg": "JPEG",
      "image/png": "PNG",
    },
  },
  contacts: {
    contact_already_updated: "Contact was already updated",
    website: "Website",
    contacts: "Contacts",
    created_at: "Created at",
    name: "Name",
    country: "Country",
    country_id: "Country id",
    address: "Address",
    action: "Action",
    no_contacts: "No contacts",
    cancel: "Cancel",
    add_contact: "Add contact",
    add_new: "Add new contact",
    edit: "Edit",
    new_contact: "New contact",
    add_contact_success: "The new contact was successfully created",
    add_contact_error: "Something went wrong while creating the new contact",
    edit_contact_success: "The new contact was successfully edited",
    edit_contact_error: "Something went wrong while editing the contact",
    contact_labels: {
      name: "Contact name",
      email: "Email",
      phone: "Phone",
    },
    org_number: "Org. number",
    vat_number: "VAT number",
    ssn: "SSN",
    postal_code: "Postal code",
    city: "City",
    email_address: "Email address",
    email_label: "Email label",
    general: "General",
    invoicing: "Invoicing",
    phone: "Phone number",
    phone_number_label: "Phone number label",
    information: "Information",
    relations: "Relations",
    files: "Files",
    add_relation: "Add relation",
    new_relation: "New relation",
    no_relations: "No relations",
    relation_type_placeholder: "Choose a relation type",
    relation_type: {
      company_signatory: "Company signatory",
      board_member: "Board member",
      beneficial_owner: "Beneficial owner",
      employee: "Employee",
      affiliate: "Affiliate",
      colluding: "Colluding",
    },
    relation_type_lowercase: {
      company_signatory: "company signatory",
      board_member: "board member",
      employee: "employee",
      affiliate: "affiliate",
      colluding: "colluding",
    },
    delete_relation_success: "Contact relation was successfully deleted",
    delete_relation_error: "Unable to delete contact relation",
    delete_relation_message: "You are about to delete this relation. Are you sure?",
    entity: "Entity",
    relation: "Relation",
    add_relation_success: "The relation was successfully added",
    add_relation_error: "The relation could not be added",
    successfully_deleted: "Contact successfully deleted",
    delete_error: "Contact could not be deleted",
    delete_contact: "Delete contact",
    delete_warning_with_name: "You are about to delete {{name}}. Are you sure?",
    delete_warning: "You are about to delete this contact. Are you sure?",
    info: "Contact information",
    vat_format_error: "Invalid VAT number format",
    save_info_btn: "Save information",
    type: "Contact type",
    contact_type: {
      organisation: "Organisation",
      individual: "Individual",
    },
    create_dealer_account: "Create dealer account",
  },
  fragus: {
    fragus: "Fragus",
    page_title: "Fragus",
    registration_number: "Registration number",
    reg_no: "Reg. no.",
  },
  carinfo: {
    carinfo: "Carinfo",
    page_title: "Carinfo",
  },
  aros: {
    aros_integration: "Aros integration",
    aros: "Aros",
    invoices: "Invoices",
    organisations: "Organisations",
    registered_at: "Registered at",
    customer_id: "Customer ID",
    org_name: "Organisation name",
    aros_reference: "Aros reference",
    actions: "Actions",
    list_invoices: "List Invoices",
    no_invoice: "No invoice",
    invoice_id: "Invoice ID",
    status: "Status",
    credited_at: "Credited at",
    initial_principal: "Initial principal",
    article_ref: "Article ref.",
    invoice_type: "Invoice type",
    borrower_ref: "Borrower ref",
    org_no: "Organisation number",
    created_at: "Created at",
    status_at: "Status at",
    principal_remaining: "Principal remaining",
    interest_remaining: "Interest remaining",
    fees_remaining: "Fees remaining",
    principal_paid: "Principal paid",
    interest_paid: "Interest paid",
    fees_paid: "Fees paid",
    payment_reference: "Payment reference",
    credit_product_id: "Credit product ID",
    servicer_ref: "Servicer ref",
    fetch_error_message: "Something went wrong while fetching data",
    table_view: "Table view",
    raw_data: "Raw data",
    no_organisation: "No organisations",
  },
  dealer_credits: {
    purchase: "Purchase",
    information: "Information",
    vehicle: "Vehicle",
    files: "Files",
    dealer: "Dealer",
    no_dealer_credits: "No dealer credits",
    purchase_info: "Purchase information",
    created_at: "Created at",
    financed_amount: "Financed amount",
    ltv: "LTV",
    remaining_financed_amount: "Remaining financed amount",
    financial_transactions: "Financial transactions",
    invoices: "Invoices",
    transactions: "Transactions",
    credit: "Credit",
  },
  location: {
    select_dealer_location: "Select dealer location",
    no_locations: "No dealer location",
    no_locations_available: "No locations available",
    add_dealer_location: "Add dealer location",
    add_location: "Add new location",
    add_location_success: "Location was successfully added",
    add_location_error: "Location could not be added",
    delete_location: "Delete location",
    delete_warning: "You are about to delete {{locationName}} location. Are you sure?",
    delete_location_success: "Location has been successfully deleted",
    delete_location_error: "Location could not be deleted",
    location_info: "Location info",
    edit_location: "Edit location",
    edit_location_success: "Location has been successfully edited",
    edit_location_error: "Location could not be edited",
  },
  transactions: {
    amount: "Amount",
    currency: "Currency",
    invoice_id: "Invoice ID",
    transaction_type: "Transaction type",
    transaction_id: "Transaction ID",
    credit: "Credit",
    debit: "Debit",
  },
  audits: {
    audit_status: {
      approved: "Approved",
      pending_review: "Pending review",
      scheduled: "Scheduled",
      reported: "Reported",
      audited: "Audited",
      pending: "Pending",
      all: "All",
      closed: "Closed",
      active: "Active",
    },
    vehicle_status: {
      ok: "OK",
      not_present: "Not present",
      present: "Present",
      remarks: "Remarks",
      not_audited: "Not audited",
      pending: "Pending",
      unknown: "-",
      audited: "Audited",
    },
    mark_as: {
      present: "Mark as present",
      not_present: "Mark as not present",
    },
    marked_as: {
      present: "Marked as present",
      not_present: "Marked as not present",
    },
    populating_list: "Populating list of vehicles {{current}}/{{total}}",
    reg_number_picture_required:
      "You don't have any photos of the vehicle uploaded. Make sure to also upload a photo where the registration number is visible.",
    update_audit_status: "Update audit status",
    set_presence_status: "Set presence status",
    schedule_audit: "Schedule audit",
    last_audit: "Last audit",
    updated_at: "Updated at",
    review: "Review",
    report: "Report to credit dep.",
    approve_audit: "Approve audit",
    close_audit: "Close audit",
    presence_status: "Presence status",
    deal_date: "Deal date",
    vin_photo: "VIN photo",
    reg_photo: "Registration number photo",
    no_notes: "No notes",
    no_photos: "No photos",
    no_vin_photo: "No VIN photo",
    no_reg_photo: "No photo of register number",
    total_vehicles: "Total vehicles",
    dealer_address: "Dealer address",
    contact_name: "Contact name",
    view_dealer: "View dealer",
    activate_audit: "Activate audit",
    registration: "Registration",
    view_vehicle: "View vehicle",
    add_general_note: "Add general note",
    general_notes: "General notes",
    add_notes: "Add notes",
    submit: "Submit",
    vehicle_not_present: "Vehicle not present",
    reasons_missing_vehicle: "Reasons for missing vehicle",
    upload_photos_with_reg: "Upload photos of vehicle including Reg",
    upload_vin_photo: "Upload VIN photo",
    upload_issues_photos: "Upload photos affecting vehicle value",
    mark_audited: "Mark as audited",
    audited_vehicle: "Audited Vehicle",
    scheduled_audits: "Scheduled Audits",
    site_audits: "Site audits",
    site_audit: "Site audit",
    vehicle_audit_details: "Vehicle audit details",
    no_audits: "No audits",
    no_cars: "No vehicle",
    vehicle_presence_update: "Updated vehicle presence status",
    update_status_error: "Error on updating audit status",
    vehicle_audit_done: "Vehicle audit successfully done",
    report_vehicle_title: "Report to credit department",
    report_btn: "Report",
    subject: "Subject",
    alert_type: "Alert type",
    alert_note: "Alert note",
    audit_status_update: "Audit status was successfully updated",
    current_vehicle_list: "Current vehicle list",
    submit_audit: "Submit audit",
  },
  dealer_account: {
    vehicle_type_not_selected: "You need to select at least one vehicle type",
    feature_flags: "Feature flags",
    features: {
      blanco_loans_enabled: "Blanco loans enabled",
      vehicle_inventory: "Vehicle inventory",
      user_management: "User management",
      credit_bonus_program: "Credit bonus program",
      own_stock_financing: "Own stock financing",
    },
    insurance_renew_date: "Insurance renewal date",
    kyc_applications: "KYC applications",
    dealer_kyc_renewal_date: "KYC renewal date",
    no_active_kyc_application: "No active application",
    credits: "Credits",
    transactions: "Transactions",
    accounts: "Accounts",
    dealer_account: "Dealer account",
    dealer_account_info_: "Dealer account information",
    default_setup_fee: "Default setup fee",
    base_interest_rate: "Base interest rate",
    interest_rate: "Interest rate",
    net_interest_rate: "Net interest rate",
    default_interest_rate_discount: "Default interest rate discount",
    interest_rate_discount: "Interest rate discount",
    add_new: "Add new dealer",
    add_new_dealer_account: "Add new dealer account",
    add_dealer_account: "Add dealer account",
    save_dealer: "Save dealer account",
    financial_email_error_message: "Something went wrong while generating the financial status email",
    send_financial_email: "Send Financial Status Email",
    customer_to: "Customer to",
    credit_limit: "Credit limit",
    credit_limit_renew_date: "Credit limit renew date",
    max_vehicle_price: "Max. vehicle price",
    max_ltv: "Max. LTV",
    reference_key: "Reference key",
    reference_value: "Reference value",
    risk_class: "Risk class",
    select_risk_class: "Select a risk class",
    risk_class_options: {
      gold: "Gold",
      silver: "Silver",
      bronze: "Bronze",
    },
    interest_accrual_scheme: "Interest accrual scheme",
    interest_accrual_placeholder: "Select interest accrual scheme",
    interest_accrual_scheme_options: {
      daily: "Daily",
      monthly: "Monthly",
    },
    default_loan_servicer: "Default loan servicer",
    default_loan_servicer_placeholder: "Select default loan servicer",
    default_loan_servicer_options: {
      aros: "Aros",
      hf: "HF",
    },
    default_loan_financier: "Default loan financier",
    default_loan_financier_placeholder: "Select default loan financier",
    default_loan_financier_options: {
      internal: "Internal",
      aros: "Aros",
      penser: "Penser",
    },
    default_credit_product: "Default credit product",
    default_credit_product_placeholder: "Select default credit product",
    default_credit_product_options: {
      "hf-120-day": "HF 120 day",
      "hf-120-day-leasing": "HF 120 day leasing",
      "hf-daily-interest-leasing": "HF daily interest leasing",
      hf_daily_interest: "HF daily interest",
    },
    price_scheme: "Price scheme",
    legal_entity: "Legal entity",
    search_name: "Search name",
    aros_customer_number: "Aros customer number",
    visma_id: "Visma id",
    hubspot_id: "Hubspot id",
    erp_customer_id: "ERP customer id",
    new_reference: "New reference",
    org_number: "Org. number",
    org_name: "Org. Name",
    location_name: "Location name",
    delete_dealer_account: "Delete dealer account",
    delete_confirmation: "You are about to delete this dealer account. Are you sure?",
    delete_success: "Dealer account was successfully deleted",
    delete_error: "Unable to delete dealer account",
    legal_entity_not_selected: "You need to select a legal entity",
    add_success: "Dealer account was successfully added",
    add_error: "Dealer account could not be added",
    edit_success: "Dealer account was edited successfully",
    edit_error: "Dealer account could not be edited",
    no_required_actions: "No required actions",
    action_type: {
      ownership_transfer_outstanding: "Ownership transfer outstanding",
      payment_outstanding: "Payment outstanding",
      credit_limit_exceeded: "Credit limit exceeded",
      contact_support: "Contact support",
    },
    overdue_send_reminder_email: "Send reminder email for all overdue",
    credit_available: "Credit available",
    of_which_reserved: "of which reserved",
    sales_rep: "Sales representative",
    notifications: {
      notifications_config: "Notifications configuration",
      notification_channel: "Notification channel",
      notification_labels: {
        all: "All",
        invoicing_all: "Invoicing all",
        invoicing_advance: "Invoicing advance",
        invoicing_financing: "Invoicing financing",
        invoicing_credit: "Invoicing credit",
        payments_all: "Payments all",
        payments_leasing_notice: "Payments leasing notice",
        payments_reminder: "Payments reminder",
      },
      edit_listener: "Edit listener",
      add_listener: "Add listener",
      remove_listener: "Remove listener",
      select_channel: "Select channel",
      channel_options: {
        email: "Email",
        sms: "SMS",
      },
      remove_listener_title: "Remove notification listener",
      cancel: "Cancel",
      confirm_btn: "Confirm",
      remove_listener_message: "Are you sure you want to remove this listener?",
    },
    allowed_vehicle_types: "Allowed vehicle types",
    pricing_rule: "Pricing rule",
    pricing_rule_placeholder: "Select pricing rule",
  },
  deals: {
    export_deal_transactions_error: "Error on exporting deal transactions",
    export_deal_transactions: "Export deal transactions",
    add_new_deal: "Add new deal",
    no_deal: "No Deal",
    previous_deals: "Previous deals",
    vehicle_in_financing_partner: "Purchased vehicle financing partner",
    vehicle_out_financing_partner: "Sold vehicle financing partner",
    vehicle_in_vat_deposit: "Purchased vehicle VAT deposit",
    purchase_contract: "Purchase contract",
    financing_contract: "Financing contract",
    insurance_contract: "Insurance contract",
    marketplace_order: "Marketplace order",
    collaboration_agreement: "Collaboration agreement",
    cancel_deal: "Cancel deal",
    cancel_deal_prompt: "Are you sure you want to cancel the deal?",
    cancel_success: "Deal was successfully cancelled",
    cancel_error: "Deal could not be cancelled",
    entity_removed_success: "was successfully removed from the deal",
    entity_removed_error: "could not be removed from the deal",
    remove_from_deal: "Remove from deal",
    deal_activated_success: "Deal was successfully activated",
    deal_activated_error: "Deal could not be activated",
    deal_initiated: "Deal initiated",
    initiate_deal: "Initiate deal",
    initiate_deal_prompt: "Are you sure you want to initiate the deal?",
    initiate: "Initiate",
    assessment_success: "Assessment was successfully created for {{section}} deal section",
    assessment_error: "Assessment could not be created for {{section}} deal section",
    mark_as_done: "Mark as done",
    assess_section: "Assess {{section}}",
    assess_prompt: "Are you sure you want to mark the following assessment as done?",
    assessed_by: "Assessed by",
    reassessment_success: "Deal {{section}} section was successfully reassessed",
    reassessment_error: "Deal {{section}} section could not be successfully reassessed",
    re_open: "Re-open",
    export_deals_to_csv: "Export deals to CSV",
    edit_entity: {
      vehicle: "Edit vehicle",
      legal_entity: "Edit contact",
      dealer_account: "Edit dealer",
    },
    deal_sections: {
      vehicle: "Vehicle",
      dealer: "Dealer",
      dealer_account: "Dealer",
      purchase: "Purchase",
      offering: "Offering",
      contracting: "Contracting",
      payments: "Payments",
      advance: "Advance",
      payout: "Payout",
      ownership: "Ownership",
      servicing: "Servicing",
    },
    deal_status: {
      initiated: "Initiated",
      offer_requested: "Offer requested",
      pending: "Pending",
      offer_accepted: "Offer accepted",
      prepared: "Prepared",
      servicing: "Servicing",
      cancelled: "Cancelled",
      settled: "Settled",
    },
    process_step: {
      all: "All",
      new: "New",
      offer_accepted: "Offer accepted",
      contracts_signed: "Contracts signed",
      payout_pending: "Payout pending",
      active: "Active",
      settlement_received: "Settlement received",
      settled: "Settled",
    },
    process_state: {
      init: "Init",
      pending: "Pending",
      closed: "Closed",
      cancelled: "Cancelled",
      expired: "Expired",
      rejected: "Rejected",
      error: "Error",
    },
    section_roles: {
      dealer: "Dealer",
      seller: "Seller",
      seller_agent: "Seller Agent",
      seller_financier: "Seller Financier",
      vat_deposit_escrow: "VAT Deposit",
      transferor: "Ownership Transferor",
    },
    credit_simulation: {
      balances: {
        interest_prepaid: "Interest prepaid",
        principal_paid: "Principal paid",
        borrower_settled: "Borrower settled",
        originator_credit: "Originator credit",
        principal_outstanding_not_due: "Principal outstanding not due",
        originator_expenses: "Originator expenses",
        borrower_balance: "Borrower balance",
        fees_outstanding_due: "Fees outstanding due",
        interest_outstanding_not_due: "Interest outstanding not due",
        fees_paid: "Fees paid",
        interest_outstanding_due: "Interest outstanding due",
        principal_outstanding_due: "Principal outstanding due",
        interest_paid: "Interest paid",
        borrower_credit: "Borrower credit",
      },
    },
    deposit_link: {
      generate_link_title: "Generate deposit link",
      generate_link_btn_label: "Generate link",
      generated_link: "Generated link",
      purpose_label: "Purpose",
      purpose: {
        advance: "Advance",
        settlement: "Settlement",
        extend: "Extend",
      },
    },
    payout_link: {
      generate_payout_link: "Get payment link",
      initiate_payment: "Pay now",
      generate_link_btn_label: "Generate link",
      generated_link: "Generated link",
      generate_link_error: "Something went wrong while generating the payout link",
      initiate_payment_success_message: "Payment initiated successfully",
      initiate_payment_error_message: "Payment could not be initiated successfully",
      approve_transfer: "Approve transfer",
      approve_transfer_success_message: "Transfer was successfully approved",
      approve_transfer_error_message: "Transfer could not be approved",
    },
  },
  deal_vehicle: {
    vehicle_assessment: "Vehicle assessment",
    information: "Information",
    import: "Import",
    current_valuation: "Current valuation",
    search_label: "Search or add new vehicle",
    search_placeholder: "Search vehicle",
    vehicle_add_success: "Vehicle was successfully added to the deal",
    vehicle_add_error: "Vehicle could not be added to the deal",
    indicative_first_reg_date: "Indicative first registration date",
    indicative_mileage: "Indicative mileage",
    indicative_vat_status: "Indicative VAT status",
    dealer_comments: "Dealer comments",
    additional_info: "Additional info",
    no_additional_info: "No additional info",
    by: " by ",
    dealer_communication: "Dealer communication",
  },
  deal_dealer: {
    information: "Information",
    search_label: "Search or add new dealer",
    search_placeholder: "Search dealer",
    search_contact_label: "Search or add new contact",
    search_contact_placeholder: "Search contact",
    dealer_add_success: "Dealer was successfully added to the deal",
    dealer_add_error: "Dealer could not be added to the deal",
    customer_to: "Customer to",
    org_number: "Org. number",
    vat_number: "VAT number",
    interest_accrual_scheme: "Interest accrual scheme",
    default_loan_servicer: "Default loan servicer",
    credit_limit: "Credit limit",
    used_credit: "Used credit",
    available_credit: "Available credit",
    reference_key: "Reference key",
    reference_value: "Reference value",
    deals_as_seller: "Previous deals (as seller)",
    deals_as_buyer: "Previous deals (as buyer)",
    dealer_assessment: "Dealer assessment",
    dealership_contacts: "Dealership contacts",
    add_dealer_contact: "Add dealer contact",
    mandatory_contact_field: "Contact field is mandatory",
    add_dealer_contact_success: "Dealer contact was successfully added",
    add_dealer_contact_error: "Dealer contact could not be added",
    of_which_reserved: "of which reserved",
  },
  deal_advance: {
    attach_file_error: "Error on attaching file to deal advance",
    attach_file_success: "File was successfully attached to deal advance",
    attach_files_success: "Files were successfully attached to deal advance",
    advance_assessment: "Advance assessment",
    advance_to_collect: "Advance to collect:",
    amount: "Amount",
    advance_invoices: "Advance invoices",
  },
  deal_sections: {
    article_warning: "The vehicle VAT status is different from the selected article.",
    allow_own_stock_financing: "Own stock financing - permitted for dealer",
    own_stock_financing_not_allowed: "Own stock financing - not allowed for dealer",
    roles: "Roles",
    update_entity_title: "Update entity",
    confirm_btn: "Confirm",
    update_indicative_info: "Update missing fields with indicative info",
    overwrite_indicative_info: "Overwrite all fields with indicative info",
    update_entity_options: "How do you want to proceed?",
    create_contact: "Create contact",
    swift_bic: "SWIFT/BIC",
    iban: "IBAN",
    articles: {
      vehicle_vat_deductible: "VAT deductible vehicle",
      vehicle_margin_scheme: "Margin scheme vehicle",
      fee: "Fee",
      vehicle_loan: "Vehicle loan",
      down_payment: "Down payment",
      trade_out: "Trade out",
      vat_deposit: "VAT deposit",
      fee_delayed: "Fee delayed",
      deficit: "Deficit",
    },
    edit_section_articles_success: "Section articles were successfully updated",
    edit_section_articles_error: "Section articles could not be updated",
    search_placeholder: "Search contact",
    add_additional: "+ Section",
    select_contact_message: "You need to select one contact for section",
    section_added_success: "Section was successfully added to deal",
    section_added_error: "Section could not be added to deal",
    section_edit_success: "Section was successfully updated",
    section_edit_error: "Section could not be updated",
    save_section: "Save section",
    section_associated_error: "This contact is already associated with a deal section",
    section_indicative_info: "Section indicative information",
    indicative_name: "Indicative name",
    indicative_address: "Indicative address",
    indicative_ssn: "Indicative SSN",
    indicative_org_no: "Indicative org. no",
    indicative_country_id: "Indicative country id",
    indicative_phone: "Indicative phone",
    indicative_email: "Indicative email",
    save_articles: "Save articles",
    edit_section: "Edit section",
  },
  deal_purchase: {
    purchase_assessment: "Purchase assessment",
    purchase_updated_success: "Purchase was successfully updated",
    purchase_updated_error: "Purchase could not be updated",
    intra_community: "Intra community",
    price_no_vat: "Price excl vat",
    vat: "VAT",
    total: "Total",
    search_placeholder: "Search from vehicles",
    add_article: "Add article",
    article: "Article",
    payouts: "Payouts",
    amount_net: "Amount net",
    amount_vat: "Amount VAT",
    total_amount: "Total amount",
    total_purchase_price: "Total purchase price",
    purchase_files: "Purchase files",
    create_section_from_file: "Create section from file",
    add_documents: "Documents",
    select_document_placeholder: "Select document",
    add_documents_label: "Add documents label",
    add_documents_success: "Documents were successfully added",
    add_documents_error: "Documents could not be added to deal section",
    file_attached_error: "Error on attaching file to deal {{anchor_type}}",
    file_attached_success: "File was successfully attached to deal {{anchor_type}}",
    files_attached_success: "Files were successfully attached to deal {{anchor_type}}",
    file_open_error: "Error on opening file",
    indicative_signatory: {
      add_relation_error: "Error on adding indicative signatory relation",
      seller_signatory: "Indicative seller signatory",
      create_signatory: "Create signatory",
      name: "Name",
      email: "Email",
      phone: "Phone",
      ssn: "SSN",
    },
  },
  deal_offering: {
    confirmation_modal: {
      title: "Set default offer",
      warning_message: "This will update the deal with the default offer values. Are you sure you want to proceed?",
      cancel: "Cancel",
      confirm_btn: "Confirm",
    },
    set_default_offer: "Set default offer",
    offer_accepted_error: "Error on accepting deal offering",
    offer_accepted: "Offer accepted",
    accept_offer: "Accept offer",
    max_financing_amount: "Max. financing amount",
    financing_amount_exceeded: "The amount is greater than the max financing amount",
    offering_assessment: "Offering assessment",
    data_fetch_error: "Something went wrong while fetching the deal",
    purchase_price_prompt: "You need to set the vehicle's purchase price",
    offering_update_success: "Deal offering was successfully updated",
    offering_update_error: "Something went wrong while updating deal offering",
    resolve_offer_prompt: "Something went wrong while resolving deal offering",
    financing_rate: "Financing rate",
    financing_amount: "Financing amount",
    interest_rate: "Interest Rate",
    interest_rate_placeholder: "Select interest rate",
    setup_fee: "Setup fee",
    default_setup_fee: "Default setup fee",
    new_setup_fee: "New setup fee",
    override: "Override",
    metrics: "Metrics",
    valuation: "Valuation",
    vehicle_total_price: "Vehicle sales price",
    ltv: "LTV",
    ptv: "PTV",
    save_info: "Save information",
    no_vehicle_added: "No vehicle added",
    publish: "Publish",
    publish_success: "Deal offering was successfully published",
    publish_error: "Something went wrong while publishing deal offering",
    dealer_requested_financing_amount: "Requested financing amount by dealer",
  },
  deal_data_room: {
    contract_signee_error: "Error on fetching contract signee: {{message}}",
    fetch_contract_signee_error: "Error on fetching contract signee",
    data_room_assessment: "Data room assessment",
    signing_processes: "Signing processes",
    generate_contract: "Generate contract",
    document_type: "Document type",
    upload_document: "Upload document",
    upload_contract: "Upload contract",
    select_contract_placeholder: "Select contract type",
    signatories: "Signatories",
    contract_preview: "Contract Preview",
    select_contract_prompt: "Select a contract type to preview",
    contract_signing_initiated_success: "Contract was successfully created and signing process initiated",
    contract_signing_initiated_error: "Could not initiate contract signing",
    contract_initiate_error: "Contract initiate error: {{message}}",
    contract_preview_error: "Contract preview error: {{message}}",
    add_contract_error: "Add contract error: {{message}}",
    invoices: "Invoices",
    create_invoice: "Create invoice",
    create_advance_invoice: "Create advance invoice",
    create_financing_invoice: "Create financing invoice",
    import_invoice: "Import invoice",
    import_advance_invoice: "Import advance invoice",
    create_invoice_success: "Invoice was successfully created",
    create_invoice_error: "Invoice could not be created",
    invoice_type: "Invoice type",
    invoice_types: {
      advance: "Advance",
      financing: "Financing",
      credit_financing: "Credit financing",
      credit_balancing: "Credit balancing",
      interest: "Interest",
      leasing: "Leasing",
    },
    provider_references_prompt: "Provider references is mandatory",
    invoice_import_success: "Invoice was successfully imported",
    invoice_import_error: "Invoice could not be imported",
    payment_type: "Payment type",
    provider_reference: "Provider reference",
    due: "Due",
    balance: "Balance",
    provider: "Provider",
    tenant: "Tenant",
    article: "Article",
    description: "Description",
    vat: "VAT",
    net: "Net",
    view: "View",
    re_import: "Re-Import",
    send_to_partner: "Send to financing partner",
    refreshed_from_briox_success: "Invoice was successfully refreshed from ERP",
    refreshed_from_briox_error: "Invoice could not be refreshed from ERP",
    send_to_partner_success: "Invoice was successfully sent to financing partner",
    send_to_partner_error: "Invoice could not be sent to financing partner",
    created: "Created",
    number: "No",
    purpose: "Purpose",
    total: "Total",
    supplier_name: "Supplier",
    attach_file_to_erp: "Attach files to ERP",
    attach_file: "Attach file",
    status: "Status",
    invoice_status: {
      paid: "Paid",
      paid_invoice: "Paid invoice",
      unpaid: "Unpaid",
      overdue: "Overdue",
      unbooked: "Unbooked",
      booked: "Booked",
    },
  },
  deal_payout: {
    payout_assessment: "Payout assessment",
    payouts: "Payouts",
    vehicle_purchase_price: "Vehicle purchase price",
    payout_cancel_success: "Payout task was successfully cancelled",
    payout_cancel_error: "Payout task could not be cancelled",
    payout_done_success: "Payout task was successfully marked as done",
    payout_done_error: "Payout task could not be marked as done",
    attach_file_error: "Error on attaching file to {{deal}}",
    attach_file_success: "File was successfully attached to {{deal}}",
    attach_files_success: "Files were successfully attached to {{deal}}",
    replace_receipt: "Replace receipt",
    attach_receipt: "Attach receipt",
    upload_document: "Upload document",
    purchase_accounting: "Purchase accounting",
    finance_invoices: "Finance invoices",
    create_new_supplier_invoice: "Create new supplier invoice",
    select_supplier: "Select supplier",
    attach_file_prompt: "You need to attach a file",
    select_supplier_prompt: "You need to select a supplier",
    erp_attach_file_success: "File was successfully attached to ERP",
    erp_attach_file_error: "File could not be attached to ERP",
    vat_amount: "VAT Amount",
    supplier_invoices_status: {
      booked: "Booked",
      draft: "Draft",
      voided: "Voided",
    },
    payment_to: "Payment to",
    payout_status: {
      new: "New",
      in_process: "In process",
      processed: "Processed",
      cancelled: "Cancelled",
      pending: "Pending",
      authorized: "Authorized",
      rejected: "Rejected",
      approved: "Approved",
      expired: "Expired",
    },
  },
  deal_ownership: {
    ownership_assessment: "Ownership's assessment",
    checklist: "Checklist",
    ownership_updated_success: "Ownership was successfully updated",
    ownership_updated_error: "Ownership could not be updated",
    registration_papers_received: "Registration papers received",
    authorization_code: "Authorization code",
    ownership_transfer: "Ownership transfer",
    purchase_transactions: "Purchase transactions",
    sale_transactions: "Sale transactions",
    mark_as_done: "Mark as done",
    updated: "Updated",
    control_no: "Control number",
    enter_control_no: "Enter control number",
    initiate_purchase: "Initiate purchase",
    owner: "Owner",
    message: "Message",
    lease: "Lease",
    transfer_ownership: "Transfer ownership",
    seller_transfers_ownership: "Seller transfers ownership",
    status: {
      done: "Done",
      pending: "Pending",
    },
    error_codes: {
      same_seller_and_buyer: "Same seller and buyer",
      leasing_credit_conflict: "Leasing credit conflict",
      provider_service_error: "Provider service error",
      dealer_not_owner: "Dealer not owner",
      registration_not_allowed: "Registration not allowed",
    },
    initiate_trade_in_success: "Trade-in was successfully initiated",
    initiate_trade_in_error: "Trade-in could not be initiated",
    initiate_leasing_success: "Leasing was successfully assigned to dealer",
    initiate_leasing_error: "Leasing could not be initiated",
    ownership_trade_in_success: "Ownership trade-in was successfully completed",
    ownership_trade_in_error: "Ownership trade-in could not be completed",
    ownership_sale_success: "Ownership to dealer was successfully completed",
    ownership_sale_error: "Ownership to dealer could not be completed",
    create_sale_transaction_success: "Sale transaction was successfully created",
    create_sale_transaction_error: "Sale transaction could not be created",
    registration_papers_received_success: "Registration papers received was successfully updated",
    registration_papers_received_error: "Registration papers received could not be updated",
    trade_in_complete: "Trade-in complete",
  },
  deal_servicing: {
    financials: "Financials",
    deal_log: "Deal log",
    loan_status: "Loan status",
    payment_options: "Payment options",
    aros_invoices: "Aros invoices",
    borrower_ref: "Borrower ref",
    credited_at: "Credited at",
    status_at: "Status at",
    principal_remaining: "Principal remaining",
    interest_remaining: "Interest remaining",
    fees_remaining: "Fees remaining",
    principal_paid: "Principal paid",
    interest_paid: "Interest paid",
    fees_paid: "Fees paid",
    payment_reference: "Payment reference",
    credit_product_id: "Credit product ID",
    servicer_ref: "Servicer ref",
    customer_id: "Customer ID",
    article_ref: "Article ref",
    aros_reference: "Aros reference",
    log_output: "Log output",
    principal: "Principal",
    payments_received: "Payments received",
    add_payment: "Add payment",
    add_payment_received: "Add payment received",
    edit_payment_received: "Edit payment received",
    no_log_data: "No log data",
    go_to_servicing: "Move to servicing",
    deal_servicing_error: "Error on changing deal status",
    deal_servicing_success: "Deal changed to servicing status",
    checks: "Checks",
    dealer_credit: "Dealer credit",
    loan_simulation: "Loan simulation",
  },
  deal_deposits: {
    view_deposit_details: "Deposit details",
    deposits: "Deposits",
    created_at: "Created",
    deposit_ref: "Deposit ref",
    amount: "Amount",
    status: "Status",
    provider_key: "Provider key",
    provider_ref: "Provider ref",
    hf_tenant_key: "Tenant",
    transaction_id: "Transaction Id",
    dealer_id: "Dealer",
    sender_ref: "Sender ref",
    currency: "Currency",
    deleted_at: "Deleted",
    updated_at: "Updated",
  },
  alerts: {
    snooze_error: "Something went wrong while snoozing alert",
    successfully_snoozed: "Alert was successfully snoozed",
    snooze_alert_message: "You are about to snooze this alert for 12 hours. Are you sure you want to do this?",
    snooze_alert_title: "Snooze alert",
    snooze: "Snooze",
    export_success: "Alerts were successfully exported",
    export_failed: "Export failed",
    export_failed_message: "Something went wrong while exporting alerts",
    select_tenant_placeholder: "Select tenant key",
    tenant: "Tenant",
    alerts: "Alerts",
    type: "Type",
    successfully_updated: "Alert state was successfully updated",
    successfully_added: "Alert was successfully added",
    update_error: "Something went wrong while updating alert state",
    new_alert: "New alert",
    alert_type: "Alert type",
    add_new_alert: "Add new alert",
    select_alert_type: "All alert types",
    select_alert_state: "Any state",
    no_alert: "No alert",
    mark_as_handled: "Mark as handled",
    alert_note: "Alert Note",
    description: "Description",
    full_description: "Alert full description",
    alert_types: {
      incomplete_information: "Incomplete information",
      unsatisfactory_audit: "Unsatisfactory audit",
      suspicious_activity: "Suspicious activity",
      payment_issues: "Payment issues",
      contract_breach: "Contract breach",
      criminal_activity: "Criminal activity",
      vehicle_ownership_issue: "Vehicle ownership issue",
      credit_limit_exceeded: "Credit limit exceeded",
      payment_unverified: "Payment unverified",
      credit_limit_renew_date_exceeded: "Credit limit renew date exceeded",
      missing_valid_kyc: "Missing valid KYC",
    },
    alert_states: {
      unresolved: "Unresolved",
      handled: "Handled",
      dismissed: "Dismissed",
    },
  },
  monitoring: {
    observations: "Observations",
    observation: "Observation",
    schedules: "Schedules",
    observed_at: "Observed at",
    subject: "Subject",
    source: "Source",
    status: "Status",
    frequency: "Frequency",
    last_check: "Last check",
    next_check: "Next check",
    state: "State",
    all_schedule_state: "All states",
    all_sources: "All sources",
    schedule_state: {
      idle: "Idle",
      running: "Running",
      error: "Error",
    },
    periods: {
      hourly: "Hourly",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
    },
    entity_types: {
      vehicle: "Vehicle",
      legal_entity: "Contact",
      dealer_account: "Dealers",
    },
    create_note: "Create note",
    successfully_run_new_check: "Monitoring check successfully run",
    monitoring_check_error: "Monitoring check failed",
    successfully_deleted_schedule: "Monitoring schedule successfully deleted",
    general_error_message: "Something went wrong, please try again",
    delete_schedule: "Cancel monitoring schedule",
    cancel_monitoring: "Cancel monitoring",
    delete_warning: "You are about to cancel this monitoring schedule. Are you sure?",
    cancel: "Cancel",
    delete: "Delete",
    new_monitoring_schedule: "New monitoring schedule",
    add_schedule: "Add schedule",
    schedule_added_success: "Monitoring schedule was successfully added",
    schedule_form_error: "Can't add schedule, please check the form",
    subject_type: "Subject type",
    monitoring_frequency: "Monitoring frequency",
    run_now: "Run now",
    sources_types: {
      fragus: "Fragus",
      gtx: "GTX",
    },
  },
  labels: {
    ownership: "Ownership",
    servicing: "Servicing",
    contract: "Contract",
    vehicle: "Vehicle",
    invoice: "Invoice",
    receipt: "Receipt",
    contract_base: "Contract base",
    payout_receipt: "Payout receipt",
    advance_receipt: "Advance receipt",
    document_type: "Document type",
    no_labels_on_file: "No labels on file",
    no_file_label: "Other",
    add_label: "Add a label",
    image: "Image",
    pdf: "Pdf",
    purchase_contract: "Purchase contract",
    ownership_paper: "Ownership paper",
    proof_of_vehicle: "Proof of vehicle",
    labels: "Labels",
    supplier_invoice: "Supplier invoice",
    proforma_invoice: "Proforma invoice",
    debt_note: "Debt note",
    general_data: "General data",
    vin: "VIN",
    defects: "Defects",
    kyc: "KYC",
    customer_invoice: "Customer invoice",
  },
  entity_types: {
    vehicle: "Vehicle",
    legal_entity: "Contact",
    dealer_account: "Dealer",
    internal_user: "Internal user",
    deal: "Deal",
    dealer_location: "Dealer location",
    outbound_email: "Outbound email",
    monitoring_schedule: "Monitoring schedule",
  },
  incoming_payments: {
    incoming_payments: "Incoming payments",
    source: "Source",
    provider_label: "Provider",
    provider: {
      any: "Any",
      aros: "Aros",
      zimpler: "Zimpler",
    },
    reference_no: "Reference no",
    ref_no: "Ref no",
    status_options: {
      new: "New",
      booked: "Booked",
      ignored: "Ignored",
      error: "Error",
    },
    no_payments: "No payments",
    customer_no: "Customer number",
    settlement_note_no: "Settlement note number",
    settlement_note_no_short: "Settlement note no",
    amount: "Amount",
    invoice_no: "Invoice number",
    invoice_no_short: "Invoice no",
    transaction_id: "Transaction ID",
    payment_type: "Payment type",
    payment_types: {
      inbound: "Inbound",
      outbound: "Outbound",
    },
    add_new_payment: "Add new payment",
    add_payment: "Add payment",
    validation_prompt: "All fields are mandatory",
    payment_status_added_success: "Payment status was successfully added",
    payment_status_added_error: "Payment status could not be added",
    change_payment_status: "Change payment status",
    change_status: "Change status",
    delete_payment: "Delete payment",
    delete_prompt: "Are you sure you want to delete {{transactionId}} payment?",
    payment_status_updated_success: "Payment status was successfully updated",
    payment_status_updated_error: "Payment status could not be updated",
    payment_status_delete_success: "Payment was successfully deleted",
    payment_status_delete_error: "Payment could not be deleted",
    registered_at: "Registered at",
    raw_data: "View raw data",
  },
  customer_invoices: {
    from_date: "From date",
    to_date: "To date",
    customer_invoices_title: "Customer invoices",
    customer_invoice_title: "Invoice #{{invoiceNo}}",
    provider: "Provider",
    amount: "Amount",
    currency: "Currency",
    due_date: "Due date",
    raw_data: "View raw data",
    invoice_raw_data: "Invoice raw data",
    see_deal: "See deal",
    provider_ref: "Provider ref",
    financing_partner: "Financing partner",
    type: "Type",
    status: "Status",
    type_options: {
      financing: "Financing",
      advance: "Advance",
      credit_balancing: "Credit balancing",
      credit_financing: "Credit financing",
      interest: "Interest",
    },
    status_options: {
      paid: "Paid",
      unpaid: "Unpaid",
      overdue: "Overdue",
      unbooked: "Unbooked",
      booked: "Booked",
    },
  },
  checks: {
    no_checks: "No checks",
    resolved_checks: "Resolved checks",
    checks: "Checks",
    type: "Type",
    due_date: "Due date",
    valid_until: "Valid until",
    status: "Status",
    context: "Context",
    assertion_types: {
      registration_papers_received: "Registration papers received",
      hf_registered_vehicle_owner: "HF registered vehicle owner",
      hf_registered_hf: "HF registered HF",
      proforma_replaced_by_invoice: "Proforma replaced by invoice",
      payment_received: "Payment received",
    },
    assertion_statuses: {
      pending: "Pending",
      resolved: "Resolved",
      dismissed: "Dismissed",
    },
    delete_assertion: "Delete assertion",
    delete_success_message: "Assertion was successfully deleted",
    delete_error_message: "Error on deleting ",
    delete_warning: "You are about to delete this assertion. Are you sure?",
    add: "Add",
    cancel: "Cancel",
    new_item: "New check item",
    unresolved_checks: "Unresolved checks",
    create_error_message: "Error on creating new check",
    create_success_message: "New check was successfully created",
    types: "Types",
    statuses: "Statuses",
  },
  support: {
    unavailable_chat: "Chat is currently unavailable",
    load_more: "Load more",
  },
  kyc: {
    approved_at: "Approved at",
    delete_success: "KYC application was successfully deleted",
    delete_error: "KYC application could not be deleted",
    delete: "Delete",
    delete_application: "Delete application",
    delete_application_prompt: "Are you sure you want to delete this application?",
    owners_experience: "Experience within car sells (years)",
    sourcing_channels: "Sourcing channels",
    update_status_success: "Application status was successfully updated",
    update_status_error: "Application status could not be updated",
    company_section_name: "Company",
    export_kyc_btn: "Export KYC",
    export_success_message: "Successfully exported KYC",
    export_error_message: "Error on exporting KYC",
    new_application: "New KYC application",
    new_application_success: "New KYC application was successfully created",
    new_application_error: "Error on creating new KYC application",
    page_title: "KYC",
    any_status: "Any status",
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
    submitted: "Submitted",
    no_applications: "No applications",
    add_on: "Add on",
    updated_on: "Updated on",
    subject: "Subject",
    legal_entity: "Legal entity",
    organization_name: "Org. name",
    org_no: "Org no",
    status: "Status",
    filter_table: "Filter table",
    summary: "Summary",
    register: "Register",
    reopen: "Reopen",
    reject: "Reject",
    approve: "Approve",
    beneficial_owner: "Beneficial owner",
    purpose_vehicle_price_garage: "Purpose, vehicles, price & garage",
    budget: "Budget",
    application_updated_success: "Application was successfully updated",
    application_updated_error: "Application could not be updated",
    risk_class_updated_success: "Risk class was successfully updated",
    risk_class_updated_error: "Risk class could not be updated",
    no_id_image: "No ID image",
    no_dealership_photos: "No dealership photos",
    board_members: "Board members",
    is_beneficial_owner: "Ben. owner",
    initiated_by: "Initiated by",
    validity: "Validity",
    valid_until: "Valid until",
    placeholders: {
      vehicle_sourcing_channels: "Where do you buy the majority of vehicles from?",
      describe_group_countries: "Please add which countries",
      org_countries_abroad: "Which countries",
      part_of_group: "Is the company part of a group",
      group_active_abroad: "Are any of the group entities active abroad",
      describe_org_structure: "Please describe organization structure",
      org_structure: "Organization structure",
      add_another: "Add another",
      purpose_of_using_hf: "Purpose of using Handlarfinans",
      vehicle_types: "Vehicle types",
      purchase_price_range: "Purchase price range",
      online_shop_url: "Online shop URL",
      address_of_sale_vehicle: "Address of sale vehicle",
      garage_size: "Garage size",
      number_of_cars_in_garage: "Number of cars in garage",
      yard_size: "Yard size",
      number_of_cars_in_yard: "Number of cars in yard",
      owners_years_experience_in_car_sale: "How many years of experience in the car sales industry?",
      employees: "Employees (full & part-time)",
      other_sources_of_income: "Other sources of income",
      cash_management: "Does your company deal with cash",
      dealership_photos: "Dealership photos",
      estimated_purchase_costs: "Estimated purchase costs",
      gross_profit: "Gross profit",
      expected_turnover: "Expected turnover",
      facility_expenses: "Facility expenses",
      salaries_and_employee_costs: "Salaries and employee costs",
      other_expenses: "Other expenses",
      expected_result: "Expected result",
      email: "Email",
      no_board_members: "No board members",
      percentage_of_ownership: "Percentage of ownership",
      is_beneficial_owner: "Is beneficial owner",
      is_pep: "Is PEP",
      has_additional_citizenship: "Has other citizenship",
      additional_citizenship: "Additional citizenship",
      additional_citizenship_placeholder: "Finland, Norway, ...",
      control_type: "Control type",
    },
    vehicle_types: {
      passenger_cars: "Passenger cars",
      sport_cars: "Sport cars",
      transport_vehicles: "Transport vehicles",
      campers: "Campers",
      snowmobiles_atv: "Snowmobiles & ATV",
      trailers: "Trailers",
      other: "Other",
      other_vehicle: "Other vehicle type",
    },
    purpose: {
      inventory_financing: "Inventory financing",
      expansion: "Expansion",
      debt_financing: "Debt financing",
      marketing_activities: "Marketing activities",
      other: "Other",
      purpose_other: "Other purpose",
    },
    risk_class: {
      risk_class_title: "Risk class",
      set_risk_class: "Set risk class",
      money_laundry_risk: "Money laundry risk",
      financing_terrorism_risk: "Financing terrorism risk",
      criminal_record_verified: "Criminal record verified",
      inventory_verified: "Inventory verified",
      pep_verified: "PEP verified",
    },
    board_roles: {
      holder: "Holder",
      commissioner: "Commissioner",
      substitute: "Substitute",
      ceo: "CEO",
      deputy_ceo: "Deputy CEO",
      member: "Member",
      chairman: "Chairman",
      alternate_deputy_substitute_ceo: "Alternate deputy substitute CEO",
      executive_member: "Executive member",
      vice_chairman: "Vice chairman",
    },
    managing_cash: {
      frequently: "Yes, with relative frequency",
      rarely: "Yes, not frequently and only for deposit",
      as_an_exception: "Yes, as an exception and only when the customer can prove the origin",
      no: "No",
    },
    vehicle_sourcing_channels: {
      auctions: "Auctions",
      car_dealers: "Car dealers",
      other_companies: "Other companies",
      private_individuals: "Private individuals",
      import: "Import",
    },
    ownership: {
      0: "0%",
      "0_25": "0-25%",
      "25_50": "More than 25% but not more than 50%",
      "50_75": "More than 50% but not more than 75%",
      "75_100": "More than 75% but not more than 100%",
      full: "100%",
      table_0: "0%",
      table_0_25: "0-25%",
      table_25_50: "25-50%",
      table_50_75: "50-75%",
      table_75_100: "75%-100%",
      table_full: "100%",
    },
    vehicle_price_range: {
      0: "Up to 300 000 SEK",
      1: "300 000 - 500 000 SEK",
      2: "500 000 SEK and over",
    },
    control_type_options: {
      art10:
        "ART 10 - The person has control through shares, participation, memberships, agreements or provisions in for example the articles of association.",
      art14: "ART 14 - The person is a board member or equivalent executive.",
      art20:
        "ART 20 - The person has the right to appoint or remove more than half of the board members or corresponding executives.",
      art30: "ART 30 - The person has control together with close relatives.",
      art40: "ART 40 - The person has control through other companies or associations.",
      art74: "ART 74 - The person exercises control in another way.",
    },
  },
  credit: {
    overdue: "Overdue",
    lower_overdue: "overdue",
    due_days: "{{days}} days",
    days_overdue: "{{days}} days",
    one_due_day: "1 day",
    credit_title: "Credits",
    no_credits: "No credits",
    servicer: "Servicer",
    financier: "Financier",
    initial_principal: "Initial principal",
    due_in: "Due in",
    any_servicer: "Any servicer",
    any_financier: "Any financier",
    any_status: "Any status",
    status: {
      init: "Init",
      performing: "Performing",
      overdue: "Overdue",
      default: "Default",
      written_off: "Written off",
      settled: "Settled",
      voided: "Voided",
    },
    filter_dealer: "Filter dealer",
    remaining_total: "Remaining total",
    paid_principal: "Paid principal",
    paid_interest: "Paid interest",
    paid_fees: "Paid fees",
    last_updated_at: "Last updated at",
    serviced_by: "Serviced by",
    financed_by: "Financed by",
    credit_product_key: "Credit product key",
    annual_rate: "Annual rate",
    amount_to_extend: "Amount to extend",
    amount_to_settle: "Amount to settle",
    remaining_principal: "Remaining principal",
    interest_collected: "Interest collected",
    fees_collected: "Fees collected",
    no_credit_log_entries: "No credit log entries",
    ledger_accounts: "Ledger accounts",
    financier_key_options: {
      internal: "Internal",
      aros: "Aros",
      penser: "Penser",
    },
  },
  bank_account_config: {
    bank_representation_type: "Bank representation type",
  },
  ledger: {
    reverse_transaction: "Reverse transaction",
    credit_label: "Credit",
    add_credit_id: "Add credit Id",
    dealer_label: "Dealer",
    select_dealer: "Select dealer",
    select_tenant: "Select tenant",
    ledger: "Ledger",
    accounts: "Accounts",
    transactions: "Transactions",
    account_plan: "Account plan",
    ledger_accounts: "Ledger accounts",
    ledger_transactions: "Ledger transactions",
    ledger_account_plan: "Ledger account plan",
    any_key: "Any key",
    any_transaction: "Any transaction",
    any_currency: "Any currency",
    eur: "EUR",
    sek: "SEK",
    account_id: "Account ID",
    account_key: "Account key",
    key: "Key",
    currency: "Currency",
    account_details: "Account details",
    credit_id: "Credit id",
    balance: "Balance",
    delta: "Delta",
    transaction_key: "Transaction Key",
    sequence: "Sequence",
    transaction_id: "Transaction ID",
    any_tag: "Any tag",
    account: "Account",
    rollback: "Rollback",
    transaction_deleted_success: "Transaction was successfully deleted",
    translation_details: "Transaction",
    delete_transaction: "Delete transaction",
    delete_transaction_prompt: "Are you sure you want to delete this transaction?",
    no_data_account_plan: "No data available. Please select a context first.",
    ledger_account_category_label: "Select category",
    ledger_account_category_options: {
      tenant: "Tenant",
      dealer: "Dealer",
      credit: "Credit",
    },
  },
  suggested_input_value: {
    popover_trigger: "Suggested value popover trigger",
    vat_rate: "VAT rate: {{rate}}%",
    use: "Use",
  },
  deal_warnings: {
    code: {
      seller_related_to_dealer: "Seller is related to dealer",
      seller_sold_too_much: "Seller sold too much",
      seller_criminal_activity: "Seller criminal activity",
      seller_is_dealer: "Seller is dealer",
    },
    warnings: "Warnings",
    dealer_camel_case: "Dealer",
    payment_issues: "Payment issues",
    seller_camel_case: "Seller",
    is_related_to_dealer: "is related to dealer",
    vehicle_ownership_issue: "Vehicle ownership issue",
    credit_limit_exceeded: "Credit limit exceeded",
    credit_limit_renew_date_exceeded: "Credit limit renew date exceeded",
    criminal_activity: "Criminal activity",
    incomplete_information: "Incomplete information",
    missing_valid_kyc: "Missing valid KYC",
    payment_unverified: "Payment unverified",
    contract_breach: "Contract breach",
    no_warnings: "No warnings",
    sold_too_much: "sold too much",
    see_deals: "see deals",
    has_some_criminal_activities: "has some criminal activities",
    seller_is_dealer_warning: "Own stock financing is not allowed for this dealer",
  },
  traficom: {
    traficom: "Traficom",
    page_title: "Traficom integration",
    start_parsing: "Start CSV parsing",
    import_error: "Error on importing CSV file",
    transfer_success: "CSV file was successfully imported",
    upload_file: "Upload file",
    loading_screen_title: "Please wait",
    loading_screen_subtitle: "We are parsing the uploaded document...",
  },
  loan_simulation: {
    page_title: "Loan simulation",
    principal: "Principal",
    annual_rate: "Annual rate",
    annual_rate_placeholder: "Add annual rate",
    issued_at: "Issued at",
    days_past_due: "Days past due",
    days_to_extension: "Days to extension",
    to_extend: "To extend",
    to_settle: "To settle",
    credit_product: "Credit product",
    credit_product_placeholder: "Select credit product",
    credit_product_options: {
      "hf-120-day": "HF 120 day",
      "hf-120-day-leasing": "HF 120 day leasing",
      "hf-daily-interest-leasing": "HF daily interest leasing",
      hf_daily_interest: "HF daily interest",
    },
    overdue_rate: "Overdue rate",
    overdue_rate_placeholder: "Add overdue rate",
    interest_free_days_placeholder: "Add interest free days",
    interest_free_days: "Interest free days",
    log_event_types: {
      loan_issuance: "Loan issuance",
      amounts_moved_to_due: "Amounts moved to due",
      interest_accrual: "Interest accrual",
      overdue_interest_accrual: "Overdue interest accrual",
      overdue_fees_issued: "Overdue fees issued",
      loan_settlement: "Loan settlement",
    },
    log_properties: {
      principal: "Principal",
      principal_moved: "Principal moved",
      interest_moved: "Interest moved",
      fees_moved: "Fees moved",
      total_moved: "Total moved",
      amount: "Amount",
      principal_paid: "Principal paid",
      interest_paid: "Interest paid",
      fees_paid: "Fees paid",
      total_paid: "Total paid",
    },
    log_transaction_ref: "Transaction ref.",
  },
  deposits: {
    change_state_prompt: "Are you sure you want to change deposit state?",
    change_state_title: "Change deposit state",
    state_changed_error: "Error on changing deposit state",
    state_changed_success: "Deposit state was successfully changed",
    credit_id: "Credit ID",
    dealer_id: "Dealer ID",
    erp_ref: "ERP ref",
    update_deposit_success: "Deposit was successfully updated",
    update_deposit_error: "Error on updating deposit",
    update_deposit: "Update deposit",
    no_deposits: "No deposits",
    financier_key: "Financier key",
    create_deposit_success: "Deposit was successfully created",
    create_deposit_error: "Error on creating new deposit",
    add_new_deposit: "Add new deposit",
    new_deposit: "New deposit",
    deposits: "Deposits",
    tenant: "Tenant",
    sender_type: "Sender type",
    sender_ref: "Sender ref",
    deposit_purpose: "Deposit purpose",
    state: "State",
    recipient_ref: "Recipient ref",
    sender_type_options: {
      dealer: "Dealer",
      financier: "Financier",
      internal: "Internal",
    },
    recipient_key_options: {
      hf: "HF",
      epb: "EPB",
      aros: "Aros",
      brocc: "Brocc",
    },
    provider_key_options: {
      manual: "Manual",
      zimpler: "Zimpler",
    },
    actions: "Actions",
    state_options: {
      new: "New",
      pending: "Pending",
      approved: "Approved",
      processing: "Processing",
      processed: "Processed",
      rejected: "Rejected",
      voided: "Voided",
    },
    state_actions: {
      approve: "Approve",
      process: "Process",
      reject: "Reject",
      void: "Void",
    },
    deposit_purpose_options: {
      advance: "Advance",
      settlement: "Settlement",
      extend: "Extend",
      financing: "Financing",
      dividend: "Dividend",
    },
    financier_key_options: {
      internal: "Internal",
      aros: "Aros",
      penser: "Penser",
    },
    deposit: "Deposit",
    deposit_id: "Deposit ID",
    recipient_key: "Recipient key",
    provider_key: "Provider key",
    provider_ref: "Provider ref",
    deposit_timestamp: "Deposit timestamp",
    transaction_id: "Transaction ID",
  },
  repossessions: {
    repossessions: "Repossessions",
    repossession: "Repossession",
    new_repossession: "New repossession",
    no_repossessions: "No repossessions",
    repo_initiated_at: "Repo initiated at",
    reason: "Reason",
    reason_optional: "Reason (optional)",
    reason_types: {
      insolvency: "Insolvency",
      bankruptcy: "Bankruptcy",
      fraud: "Fraud",
      other: "Other",
    },
    location_types: {
      dealer: "Dealer",
      repair_shop: "Repair shop",
      in_transit: "In transit",
      seller_garage: "Seller garage",
      impound: "Impound",
      unknown: "Unknown",
    },
    vehicle_status_types: {
      repossessed: "Repossessed",
      blanco: "Blanco",
      sold: "Sold",
      unknown: "Unknown",
    },
    details: {
      remaining_principal: "Remaining principal",
      details_title: "Details",
      initiated_at: "Repo initiated at",
      reason: "Reason",
      deal_initiated_at: "Deal initiated at",
      location: "Location",
      selling_price: "Selling price",
      net_selling_price: "Net selling price",
      total_additional_costs: "Total additional costs",
      original_valuation: "Original valuation",
      initial_remaining_principal: "Initial remaining principal",
      current_remaining_principal: "Current remaining principal",
      new_remaining_principal: "New remaining principal",
      cost_explanation: "Cost explanation",
      repossession_date: "Vehicle repossessed at",
      dealer: "Dealer",
      deal: "Deal",
      current_valuation: "Current valuation",
      total_credit_loss: "Total credit loss",
      ltv: "LTV",
      vehicle: "Vehicle",
      vehicle_status: "Vehicle status",
      vehicle_sold_at: "Vehicle sold at",
      expected_credit_loss: "Expected credit loss",
      ptv: "PTV",
      update_success: "Repossession was successfully updated",
      update_error: "Error on updating repossession",
      delete_success: "Repossession was successfully deleted",
      delete_error: "Error on deleting repossession",
      delete_title: "Delete repossession",
      delete_repossession_prompt: "Are you sure you want to delete this repossession?",
      repo_notes: "Repossession notes",
      repo_files: "Repossession files",
      depreciate_credit: "Depreciate credit",
      depreciate_credit_success: "Credit was successfully depreciated",
      depreciate_credit_error: "Error on depreciating credit",
      depreciate_credit_title: "Depreciate credit",
      depreciate_credit_warning:
        "This credit has already been depreciated. Proceed only if you are sure you want to depreciate it again.",
      default_credit: "Default credit",
      default_credit_success: "Credit was successfully defaulted",
      default_credit_error: "Error on defaulting credit",
      default_credit_title: "Default credit",
      default_credit_prompt: "Are you sure you want to default this credit?",
      credit_status: "Credit status",
    },
    initiate_repo: "Initiate repossession",
    repo_creation_success: "Repossession was successfully created",
    repo_creation_error: "Error on creating new repossession",
    repo_export_success: "Repossessions was successfully exported as csv",
    repo_export_error: "Error on exporting repossessions",
    process_step: "Process step",
    process_steps_options: {
      credit: "Credit",
      finance: "Finance",
      debt_collection: "Debt collection",
      archive: "Archive",
    },
    process_step_update_success: "Repossession process step was successfully updated",
    process_step_update_error: "Error on updating repossession process step",
  },
  risk: {
    page_title: "Risk view",
    upcoming_kyc_renewal: "Upcoming KYC renewal",
    upcoming_credit_limit_renewal: "Upcoming credit limit renewal",
    upcoming_insurance_renewal: "Upcoming insurance renewal",
    vehicle_ownership: "Vehicle ownership",
    kyc_applications: "KYC applications",
    credit_current_limit: "Current limit",
    severity: "Severity",
    severity_options: {
      high: "High",
      medium: "Medium",
      low: "Low",
    },
  },
  system_messages: {
    permission: "Permission",
    feature_flag: "Feature flag",
    page_title: "System messages",
    system_message: "System message",
    message_details: "Message details",
    edit_message: "Edit message",
    create_message: "Create message",
    message: "Message",
    type: "Type",
    severity: "Severity",
    severity_options: {
      info: "Info",
      warning: "Warning",
      error: "Error",
    },
    no_messages: "No messages",
    text: "Text",
    texts: "Texts",
    valid_from: "Valid from",
    valid_to: "Valid to",
    tenant: "Tenant",
    severity_required: "Severity level is required",
    enable_message: "Enable this message",
    delete_warning: "You are about to delete this system message. Are you sure?",
    delete_message_success: "System message was successfully deleted",
    delete_message_error: "Error on deleting system message",
    delete_message: "Delete message",
    validation_error: "Please add at least an English text",
    create_success: "System message was successfully created",
    create_error: "Error on creating system message",
    update_error: "Error on updating system message",
    update_success: "System message was successfully updated",
    en_text: "English text",
    se_text: "Swedish text",
    fi_text: "Finnish text",
  },
  loans: {
    cancel_success: "Loan application was successfully cancelled",
    cancel_error_warning: "Something went wrong while cancelling the loan application",
    cancel_application_warning: "Are you sure you want to cancel the loan application?",
    cancel_application: "Cancel application",
    loan_application: "Loan application",
    product: "Product",
    create_success: "New loan application initiated. We will send the applicant an SMS with next steps.",
    create_error_warning: "Something went wrong while creating the loan application",
    fill_all_fields: "Please fill in all required fields",
    email_or_phone_required: "Please provide a phone number.",
    initiate_application: "Initiate application",
    credit_product: "Credit product",
    loans_title: "Loans",
    applicant_email: "Applicant email",
    applicant_phone_no: "Applicant phone number",
    credit_amount: "Amount",
    new_applicant: "New applicant",
    applicant_info: "Applicant information",
    loan_application_details: "Loan application details",
    loan_details: "Loan details",
    credit_amount_warning: "The amount has to be divisible by 250",
    loan_status: {
      new: "New",
      pending: "Pending",
      approved: "Approved",
      denied: "Denied",
      paid_out: "Paid out",
      error: "Error",
      signed: "Signed",
      manual_check: "Under review",
    },
    filters: {
      all: "All",
      new: "New",
      pending: "Pending",
      manual_check: "Under review",
      approved: "Approved",
      denied: "Denied",
      paid_out: "Paid out",
      error: "Error",
      signed: "Signed",
    },
    product_key: {
      blanco: "Blanco",
    },
  },
  vehicle_make: {
    vehicle_manufacturers: "Vehicle manufacturers",
    no_vehicle_makes: "No vehicle manufacturers",
    make_name: "Manufacturer",
    make_details: "Manufacturer details",
    edit_make_details: "Edit manufacturer details",
    models: "Models",
    no_models: "No models",
    model: "Model",
    manufacturing_years: "Manufacturing years",
    validation_prompt: "All fields are mandatory",
    add_new_make: "Add new manufacturer",
    add_make: "Add manufacturer",
    make_short_name: "Short name",
    make_full_name: "Full name",
    delete_make: "Delete manufacturer",
    add_model: "Add model",
    add_new_model: "Add new model",
    model_short_name: "Model short name",
    model_full_name: "Model full name",
    first_production_year: "First production year",
    last_production_year: "Last production year",
    model_details: "Model details",
    delete_model: "Delete model",
    add_make_success: "Manufacturer was successfully added",
    add_make_error: "Error on adding manufacturer",
    edit_make: "Edit manufacturer",
    edit_make_error: "Error on updating manufacturer",
    edit_make_success: "Manufacturer was successfully updated",
    delete_make_name: "Are you sure you want to delete {{make}}?",
    delete_make_prompt: "You are about to delete the manufacturer. Are you sure?",
    delete_make_success: "Manufacturer was successfully deleted",
    delete_make_error: "Error on deleting manufacturer",
    add_model_success: "Model was successfully added",
    add_model_error: "Error on adding model",
    edit_model: "Edit model",
    edit_model_error: "Error on updating model",
    edit_model_success: "Model was successfully updated",
    delete_model_success: "Model was successfully deleted",
    delete_model_error: "Error on deleting model",
    delete_model_name: "Are you sure you want to delete {{model}}?",
    delete_model_prompt: "You are about to delete the model. Are you sure?",
  },
  pricing: {
    price_rules: "Price rules",
    new_price_rule: "New price rule",
    no_price_rules: "No price rules",
    rule_key: "Key",
    inputs: "Inputs",
    function_def: "Function definition",
    outputs: "Outputs",
    no_revisions: "No revisions",
    revision_no: "Revision #{{revisionNo}}",
    new_row: "New row",
    delete_rule: "Delete rule",
    successfully_deleted: "Price rule was successfully deleted",
    delete_error: "Error on deleting price rule",
    delete_warning: "Are you sure you want to delete this price rule?",
    publish: "Publish",
    description: "Description",
    view_pricing_rule: "View pricing rule",
    expression: "Expression",
    invalid_expression: "Invalid expression",
    error_publishing: "Error on publishing price rule",
    published_successfully: "Price rule was successfully published",
    error_running_engine: "Error on running price rule engine",
    run: "Run",
    export: "Export",
    error_exporting: "Error on exporting price rules",
    exported_successfully: "Price rules were successfully exported",
    current_revision: "Current revision",
    created_successfully: "Price rule was successfully created",
    error_creating: "Error on creating price rule",
    fill_all_inputs: "Values for all input parameters must be specified.",
    rule_key_is_required: "Rule key is required",
    clone_rule: "Clone rule",
    clone_price_rules: "Clone price rule",
    error_cloning: "Error on cloning price rule",
    cloned_successfully: "Price rule was successfully cloned",
    revert_error: "Error on reverting price rule",
    successfully_reverted: "Price rule was successfully reverted",
    revert_to_this_revision: "Revert to this revision",
    revert_to_revision: "Revert to revision",
    revert_warning: "Are you sure you want to revert to this revision?",
    input_schema: "Input schema",
    output_schema: "Output schema",
  },
};
