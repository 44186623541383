import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ILocation,
  IGetDealerLocationsPayload,
  IGetDealerLocationsResponse,
  IGetDealerLocationPayload,
  IPostDealerLocationPayload,
  IPatchDealerLocationPayload,
  IDeleteDealerLocationPayload,
  IGetDealerAccountsPayload,
  IGetDealerAccountsResponse,
  IGetDealerAccountPayload,
  IGetDealerAccountResponse,
  IPostDealerAccountPayload,
  IPostDealerAccountResponse,
  IChangeDealerAccountPayload,
  IChangeDealerAccountResponse,
  IDeleteDealerAccountPayload,
  IGenerateStatusEmailPayload,
  IGenerateStatusEmailResponse,
  IGetCreditSummaryResponse,
  IGetCreditSummaryPayload,
  IGetDealerRequiredActionsResponse,
  IGetDealerRequiredActionsPayload,
  IFetchFinancingAmountPayload,
  IFetchFinancingAmountResponse,
  IExportDealerAccountsRiskPayload,
  IExportDealerAccountsRiskResponse,
  IInitRepossessionResponse,
  IInitRepossessionPayload,
  IDealerKycStatusPayload,
  IDealerKycStatusResponse,
  IFetchNotificationTreePayload,
  IFetchNotificationTreeResponse,
  IFetchFeatureFlagsPayload,
  IFetchFeatureFlagsResponse,
  IFetchUserPermissionPayload,
  IFetchUserPermissionResponse,
} from "./types";

export const dealersAccountApi = createApi({
  reducerPath: "dealersAccountApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Location", "DealerAccount", "RequiredActions", "FinancingAmount", "Repossessions"],
  endpoints: builder => ({
    getDealerLocations: builder.query<IGetDealerLocationsResponse, IGetDealerLocationsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/dealer_location",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Location"],
    }),
    getDealerLocation: builder.query<ILocation, IGetDealerLocationPayload>({
      query: ({ accessToken, location_id }) => {
        return {
          url: `/v1/dealer_location/${location_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Location"],
    }),
    postDealerLocation: builder.mutation<{ location_id: string }, IPostDealerLocationPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/dealer_location",
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Location"],
    }),
    patchDealerLocation: builder.mutation<{ updated: boolean }, IPatchDealerLocationPayload>({
      query: ({ accessToken, location_id, name }) => {
        return {
          url: `/v1/dealer_location/${location_id}`,
          method: "PATCH",
          body: { name },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Location"],
    }),
    deleteDealerLocation: builder.mutation<{ updated: boolean }, IDeleteDealerLocationPayload>({
      query: ({ accessToken, location_id }) => {
        return {
          url: `/v1/dealer_location/${location_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Location"],
    }),
    getDealerAccounts: builder.query<IGetDealerAccountsResponse, IGetDealerAccountsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/dealer_account",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["DealerAccount"],
    }),
    getDealerAccount: builder.query<IGetDealerAccountResponse, IGetDealerAccountPayload>({
      query: ({ accessToken, account_id, ...queryParams }) => {
        return {
          url: `/v1/dealer_account/${account_id}`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["DealerAccount"],
    }),
    addDealerAccount: builder.mutation<IPostDealerAccountResponse, IPostDealerAccountPayload>({
      query: ({ accessToken, contact_id, hf_tenant_key, dealer_account, schema_version }) => {
        return {
          url: "/v1/dealer_account",
          method: "POST",
          body: { legal_entity_id: contact_id, hf_tenant_key, dealer_account, schema_version },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["DealerAccount"],
    }),
    changeDealerAccount: builder.mutation<IChangeDealerAccountResponse, IChangeDealerAccountPayload>({
      query: ({ accessToken, account_id, dealer_account, revision_id, schema_version }) => {
        return {
          url: `/v1/dealer_account/${account_id}`,
          method: "PUT",
          body: { dealer_account, revision_id, schema_version },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["DealerAccount"],
    }),
    deleteDealerAccount: builder.mutation<{ updated: boolean }, IDeleteDealerAccountPayload>({
      query: ({ accessToken, account_id }) => {
        return {
          url: `/v1/dealer_account/${account_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["DealerAccount"],
    }),
    generateStatusEmail: builder.mutation<IGenerateStatusEmailResponse, IGenerateStatusEmailPayload>({
      query: ({ accessToken, account_id }) => {
        return {
          url: `/v1/dealer_account/${account_id}/status_email`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getCreditSummary: builder.query<IGetCreditSummaryResponse, IGetCreditSummaryPayload>({
      query: ({ accessToken, account_id }) => {
        return {
          url: `/v1/dealer_account/${account_id}/credit_summary`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getDealerRequiredActions: builder.query<IGetDealerRequiredActionsResponse, IGetDealerRequiredActionsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/dealer_account_required_action",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["RequiredActions"],
    }),
    fetchMaxFinancingAmount: builder.query<IFetchFinancingAmountResponse, IFetchFinancingAmountPayload>({
      query: ({ accessToken, accountId, ...queryParams }) => {
        return {
          url: `/v1/dealer_account/${accountId}/default_financing`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["FinancingAmount"],
    }),
    exportDealerAccountsRisk: builder.mutation<IExportDealerAccountsRiskResponse, IExportDealerAccountsRiskPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/risk_export`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    initRepossession: builder.mutation<IInitRepossessionResponse, IInitRepossessionPayload>({
      query: ({ accessToken, account_id, ...payload }) => {
        return {
          url: `/v1/dealer_account/${account_id}/init_repossession`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Repossessions"],
    }),
    getKycStatus: builder.query<IDealerKycStatusResponse, IDealerKycStatusPayload>({
      query: ({ accessToken, account_id }) => {
        return {
          url: `/v1/dealer_account/${account_id}/kyc_status`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchNotificationTree: builder.query<IFetchNotificationTreeResponse, IFetchNotificationTreePayload>({
      query: ({ accessToken }) => {
        return {
          url: "/v1/dealer_account/notification_tree",
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchFeatureFlags: builder.query<IFetchFeatureFlagsResponse, IFetchFeatureFlagsPayload>({
      query: ({ accessToken }) => {
        return {
          url: "/v1/feature_flag",
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchUserPermission: builder.query<IFetchUserPermissionResponse, IFetchUserPermissionPayload>({
      query: ({ accessToken }) => {
        return {
          url: "/v1/user_permission",
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetDealerLocationsQuery,
  useGetDealerLocationQuery,
  usePostDealerLocationMutation,
  usePatchDealerLocationMutation,
  useDeleteDealerLocationMutation,
  useGetDealerAccountsQuery,
  useGetDealerAccountQuery,
  useAddDealerAccountMutation,
  useChangeDealerAccountMutation,
  useDeleteDealerAccountMutation,
  useGenerateStatusEmailMutation,
  useGetCreditSummaryQuery,
  useGetDealerRequiredActionsQuery,
  useFetchMaxFinancingAmountQuery,
  useExportDealerAccountsRiskMutation,
  useInitRepossessionMutation,
  useGetKycStatusQuery,
  useFetchNotificationTreeQuery,
  useFetchFeatureFlagsQuery,
  useFetchUserPermissionQuery,
} = dealersAccountApi;
