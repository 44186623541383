const input = {
  parts: ["addon", "field", "element"],
  baseStyle: {
    field: {},
  },
  sizes: {
    md: {
      field: {
        h: "40px",
        borderRadius: "0",
        padding: "0 12px",
      },
    },
  },
  variants: {
    outline: {
      field: {
        bg: "#fff",
        borderColor: "grayLight.500",
        _hover: {
          borderColor: "purple.500",
        },
        _focus: {
          borderColor: "purple.600",
          outline: "none",
        },
        _focusVisible: {
          borderColor: "purple.600",
          outline: "none",
          boxShadow: "none",
        },
        _disabled: {
          color: "grayMid.500",
          opacity: 1,
          bg: "disabled.500",
          borderColor: "grayLight.500",
          _hover: {
            color: "grayMid.500",
            opacity: 1,
            bg: "disabled.500",
            borderColor: "grayLight.500",
          },
        },
      },
    },
    globalSearch: {
      field: {
        color: "#fff",
        border: "1px",
        bg: "rgba(255,255,255,.16)",
        borderColor: "rgba(214,188,214,.24)",
        _placeholder: {
          color: "#fff",
        },
        _hover: {
          bg: "rgba(255,255,255,.20)",
          borderColor: "rgba(214,188,214,.32)",
        },
        _focus: {
          bg: "rgba(255,255,255,.20)",
          borderColor: "rgba(214,188,214,.54)",
        },
      },
    },
    blank: {
      field: {
        border: "none",
        bg: "white",
        _placeholder: {
          color: "grayMid.500",
        },
        _hover: {
          bg: "white",
          border: "none",
        },
        _focus: {
          bg: "white",
          border: "none",
          outline: "1px solid",
          outlineColor: "purple.600",
          outlineOffset: "0px",
        },
        _disabled: {
          color: "grayMid.500",
          opacity: 1,
          bg: "altGray.200",
          border: "none",
          _hover: {
            color: "grayMid.500",
            opacity: 1,
            bg: "altGray.200",
            border: "none",
          },
        },
      },
    },
  },
};

export default input;
