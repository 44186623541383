import Authorize from "./Authorize";

enum Permissions {
  READ_TENANT = "read:tenant",
  CREATE_INTERNAL_USER = "create:internal_user",
  READ_INTERNAL_USER = "read:internal_user",
  UPDATE_INTERNAL_USER = "update:internal_user",
  DELETE_INTERNAL_USER = "delete:internal_user",
  READ_OWN_USER = "read:own_user",
  UPDATE_OWN_USER = "update:own_user",
  READ_TRANSACTIONAL_EMAIL = "read:outbound_email",
  SEND_TRANSACTIONAL_EMAIL = "create:outbound_email",
  READ_INTERNAL_USER_ROLE = "read:internal_user_role",
  GRANT_INTERNAL_USER_ROLE = "create:internal_user_role_relation",
  REVOKE_INTERNAL_USER_ROLE = "delete:internal_user_role_relation",
  READ_DOMAIN_EVENT = "read:domain_event",
  CREATE_JSON_BLOB = "create:json_blob",
  READ_JSON_BLOB = "read:json_blob",
  UPDATE_JSON_BLOB = "update:json_blob",
  DELETE_JSON_BLOB = "delete:json_blob",
  CREATE_VEHICLE = "create:vehicle",
  READ_VEHICLE = "read:vehicle",
  UPDATE_VEHICLE = "update:vehicle",
  DELETE_VEHICLE = "delete:vehicle",
  CREATE_VEHICLE_VALUATION = "create:vehicle_valuation",
  READ_VEHICLE_VALUATION = "read:vehicle_valuation",
  UPDATE_VEHICLE_VALUATION = "update:vehicle_valuation",
  DELETE_VEHICLE_VALUATION = "delete:vehicle_valuation",
  CREATE_VEHICLE_MANUFACTURER = "create:vehicle_manufacturer",
  READ_VEHICLE_MANUFACTURER = "read:vehicle_manufacturer",
  DELETE_VEHICLE_MANUFACTURER = "delete:vehicle_manufacturer",
  UPDATE_VEHICLE_MANUFACTURER = "update:vehicle_manufacturer",
  DOMAIN_SEARCH = "create:domain_search",
  CREATE_NOTE = "create:note",
  READ_NOTE = "read:note",
  UPDATE_OWN_NOTE = "update:own_note",
  DELETE_OWN_NOTE = "delete:own_note", //
  DELETE_NOTE = "delete:note",
  CREATE_FILE = "create:file",
  READ_FILE = "read:file",
  UPDATE_OWN_FILE = "update:own_file", //
  UPDATE_FILE = "update:file",
  DELETE_OWN_FILE = "delete:own_file", //
  DELETE_FILE = "delete:file",
  CREATE_LEGAL_ENTITY = "create:legal_entity",
  READ_LEGAL_ENTITY = "read:legal_entity",
  UPDATE_LEGAL_ENTITY = "update:legal_entity",
  DELETE_LEGAL_ENTITY = "delete:legal_entity",
  CREATE_LEGAL_ENTITY_RELATION = "create:legal_entity_relation",
  READ_LEGAL_ENTITY_RELATION = "read:legal_entity_relation",
  DELETE_LEGAL_ENTITY_RELATION = "delete:legal_entity_relation",
  CREATE_DEALER_FINANCE_REPORT = "create:dealer_finance_report",
  CREATE_AROS_INTEGRATION = "create:aros_integration",
  READ_AROS_INTEGRATION = "read:aros_integration",
  UPDATE_AROS_INTEGRATION = "update:aros_integration",
  DELETE_AROS_INTEGRATION = "delete:aros_integration",
  CREATE_DEALER_CREDIT = "create:dealer_credit",
  READ_DEALER_CREDIT = "read:dealer_credit",
  UPDATE_DEALER_CREDIT = "update:dealer_credit",
  READ_INVOICE = "read:invoice",
  READ_TRANSACTION = "read:transaction",
  CREATE_DEAL = "create:deal",
  READ_DEAL = "read:deal",
  UPDATE_DEAL = "update:deal",
  DELETE_DEAL = "delete:deal",
  CREATE_DEAL_ASSESSMENT = "create:deal_assessment",
  DELETE_DEAL_ASSESSMENT = "delete:deal_assessment",
  CREATE_DEALER_LOCATION = "create:dealer_location",
  CREATE_DEAL_DEPOSIT_URL = "create:deal_deposit_url",
  READ_DEALER_LOCATION = "read:dealer_location",
  UPDATE_DEALER_LOCATION = "update:dealer_location",
  DELETE_DEALER_LOCATION = "delete:dealer_location",
  CREATE_DEALER_ACCOUNT = "create:dealer_account",
  READ_DEALER_ACCOUNT = "read:dealer_account",
  UPDATE_DEALER_ACCOUNT = "update:dealer_account",
  DELETE_DEALER_ACCOUNT = "delete:dealer_account",
  READ_CURRENCY_CONVERT = "read:currency_convert",
  CREATE_MONITORING_SCHEDULE = "create:monitoring_schedule",
  READ_MONITORING_SCHEDULE = "read:monitoring_schedule",
  UPDATE_MONITORING_SCHEDULE = "update:monitoring_schedule",
  DELETE_MONITORING_SCHEDULE = "delete:monitoring_schedule",
  READ_MONITORING_LOG = "read:monitoring_log",
  CREATE_ALERT = "create:alert",
  READ_ALERT = "read:alert",
  UPDATE_ALERT = "update:alert",
  DELETE_ALERT = "delete:alert",
  CREATE_SIGNING_PROCESS = "create:signing_process",
  READ_SIGNING_PROCESS = "read:signing_process",
  UPDATE_SIGNING_PROCESS = "update:signing_process",
  DELETE_SIGNING_PROCESS = "delete:signing_process",
  READ_SITE_AUDIT = "read:site_audit",
  CREATE_SITE_AUDIT = "create:site_audit",
  UPDATE_SITE_AUDIT = "update:site_audit",
  DELETE_SITE_AUDIT = "delete:site_audit",
  READ_SITE_VEHICLE_AUDIT = "read:site_vehicle_audit",
  CREATE_SITE_VEHICLE_AUDIT = "create:site_vehicle_audit",
  UPDATE_SITE_VEHICLE_AUDIT = "update:site_vehicle_audit",
  DELETE_SITE_VEHICLE_AUDIT = "delete:site_vehicle_audit",
  CREATE_PAYOUT_TASK = "create:payout_task",
  READ_PAYOUT_TASK = "read:payout_task",
  UPDATE_PAYOUT_TASK = "update:payout_task",
  DELETE_PAYOUT_TASK = "delete:payout_task",
  READ_LEDGER_ACCOUNT = "read:ledger_account",
  CREATE_LEDGER_TRANSACTION = "read:ledger_transaction",
  READ_LEDGER_TRANSACTION = "create:ledger_transaction",
  UPDATE_LEDGER_TRANSACTION = "update:ledger_transaction",
  UPDATE_VISIBLE_TENANTS = "update:visible_tenants",
  READ_VISIBLE_TENANTS = "read:visible_tenants",
  SELECT_OWN_TENANT = "select:own_tenant",
  CREATE_DEALER_DEPOSIT = "create:dealer_deposit",
  READ_DEALER_DEPOSIT = "read:dealer_deposit",
  UPDATE_DEALER_DEPOSIT = "update:dealer_deposit",
  DELETE_DEALER_DEPOSIT = "delete:dealer_deposit",
  CREATE_PAYOUT_TASK_WITHDRAWAL_LINK = "create:payout_task_withdrawal_link",
  CREATE_PAYOUT_TASK_WITHDRAWAL = "create:payout_task_withdrawal",
  APPROVE_PAYOUT_TASK_WITHDRAWAL = "approve:payout_task_withdrawal",
  CANCEL_PAYOUT_TASK_WITHDRAWAL = "cancel:payout_task_withdrawal",
  CREATE_EXTERNAL_USER = "create:external_user",
  READ_EXTERNAL_USER = "read:external_user",
  UPDATE_EXTERNAL_USER = "update:external_user",
  DELETE_EXTERNAL_USER = "delete:external_user",
  READ_EXTERNAL_USER_ROLE = "read:external_user_role",
  GRANT_EXTERNAL_USER_ROLE = "create:external_user_role_relation",
  REVOKE_EXTERNAL_USER_ROLE = "delete:external_user_role_relation",
  CREATE_STATE_ASSERTION = "create:state_assertion",
  READ_STATE_ASSERTION = "read:state_assertion",
  UPDATE_STATE_ASSERTION = "update:state_assertion",
  DELETE_STATE_ASSERTION = "delete:state_assertion",
  CONNECT_CHAT = "connect:chat",
  UPDATE_KYC_RECORD = "write:kyc_record",
  READ_KYC_APPLICATION = "read:kyc_application",
  UPDATE_KYC_APPLICATION = "update:kyc_application",
  DELETE_KYC_APPLICATION = "delete:kyc_application",
  READ_VAT_TABLE = "read:vat_table",
  READ_INVENTORY_VEHICLE_TRANSACTION = "read:inventory_vehicle_transaction",
  UPDATE_INVENTORY_VEHICLE_TRANSACTION = "update:inventory_vehicle_transaction",
  DELETE_INVENTORY_VEHICLE_TRANSACTION = "delete:inventory_vehicle_transaction",
  CREATE_INVENTORY_VEHICLE_TRANSACTION = "create:inventory_vehicle_transaction",
  UPDATE_VEHICLE_OWNERSHIP = "write:vehicle_ownership",
  READ_VEHICLE_OWNERSHIP = "read:vehicle_ownership",
  CREATE_VEHICLE_REPOSSESSION = "create:vehicle_repossession",
  READ_VEHICLE_REPOSSESSION = "read:vehicle_repossession",
  UPDATE_VEHICLE_REPOSSESSION = "update:vehicle_repossession",
  DELETE_VEHICLE_REPOSSESSION = "delete:vehicle_repossession",
  READ_IDENTITY_VERIFICATION = "read:identity_verification",
  UPDATE_IDENTITY_VERIFICATION = "update:identity_verification",
  CREATE_SYSTEM_MESSAGE = "create:system_message",
  READ_SYSTEM_MESSAGE = "read:system_message",
  UPDATE_SYSTEM_MESSAGE = "update:system_message",
  DELETE_SYSTEM_MESSAGE = "delete:system_message",
  CREATE_CONSUMER_CREDIT_APPLICATION = "create:consumer_credit_application",
  READ_CONSUMER_CREDIT_APPLICATION = "read:consumer_credit_application",
  UPDATE_CONSUMER_CREDIT_APPLICATION = "update:consumer_credit_application",
  DELETE_CONSUMER_CREDIT_APPLICATION = "delete:consumer_credit_application",
  CREATE_PRICING_RULE = "create:pricing_rule",
  READ_PRICING_RULE = "read:pricing_rule",
  UPDATE_PRICING_RULE = "update:pricing_rule",
  DELETE_PRICING_RULE = "delete:pricing_rule",
}

export { Permissions, Authorize };
