import { Permissions } from "../../permissions";

export type SidebarItem = {
  label: string;
  icon: string | null;
  path: string | null;
  permissions: Permissions[] | null;
  subItems?: SidebarItem[];
};

export const sidebarItems: SidebarItem[] = [
  {
    label: "deals",
    path: "/deals",
    icon: "emoji_transportation",
    permissions: [Permissions.READ_DEAL],
  },
  {
    label: "audits",
    icon: "no_crash",
    path: null,
    permissions: [Permissions.READ_SITE_AUDIT, Permissions.READ_SITE_VEHICLE_AUDIT],
    subItems: [
      {
        label: "site_audits",
        path: "/site-audits",
        icon: null,
        permissions: [Permissions.READ_SITE_AUDIT],
      },
      {
        label: "scheduled_audits",
        path: "/scheduled-audits",
        icon: null,
        permissions: [Permissions.READ_SITE_VEHICLE_AUDIT],
      },
    ],
  },
  {
    label: "checks",
    path: "/checks",
    icon: "task_alt",
    permissions: [Permissions.READ_STATE_ASSERTION],
  },
  {
    label: "risk_view",
    path: "/risk-view",
    icon: "warning_amber",
    permissions: [Permissions.READ_STATE_ASSERTION],
    // TODO: Update permissions
  },
  {
    label: "users",
    path: null,
    icon: "group",
    permissions: [Permissions.READ_INTERNAL_USER, Permissions.READ_EXTERNAL_USER, Permissions.READ_LEGAL_ENTITY],
    subItems: [
      {
        label: "internal_users",
        path: "/user-management",
        icon: null,
        permissions: [Permissions.READ_INTERNAL_USER],
      },
      {
        label: "external_users",
        path: "/external-users",
        icon: null,
        permissions: [Permissions.READ_EXTERNAL_USER],
      },
      {
        label: "onboarding_users",
        path: "/onboarding-users",
        icon: null,
        permissions: [Permissions.READ_EXTERNAL_USER],
      },
      {
        label: "contacts",
        path: "/contacts",
        icon: null,
        permissions: [Permissions.READ_LEGAL_ENTITY],
      },
    ],
  },
  {
    label: "client_management",
    path: null,
    icon: "corporate_fare",
    permissions: [
      Permissions.READ_VEHICLE_REPOSSESSION,
      Permissions.READ_DEALER_ACCOUNT,
      Permissions.UPDATE_KYC_RECORD,
    ],
    subItems: [
      {
        label: "repossessions",
        path: "/repossessions",
        icon: null,
        permissions: [Permissions.READ_VEHICLE_REPOSSESSION],
      },
      {
        label: "dealer_accounts",
        path: "/dealer-accounts",
        icon: null,
        permissions: [Permissions.READ_DEALER_ACCOUNT],
      },
      {
        label: "consumer_loans",
        path: "/loans",
        icon: null,
        permissions: [Permissions.READ_CONSUMER_CREDIT_APPLICATION],
      },
      {
        label: "kyc",
        path: "/kyc",
        icon: null,
        permissions: [Permissions.UPDATE_KYC_RECORD],
      },
    ],
  },

  {
    label: "ledger",
    path: null,
    icon: "list_alt",
    permissions: [Permissions.READ_LEDGER_ACCOUNT],
    subItems: [
      {
        label: "ledger_account_plan",
        path: "/ledger-account-plan",
        icon: null,
        permissions: [Permissions.READ_LEDGER_ACCOUNT],
      },
      {
        label: "ledger_accounts",
        path: "/ledger-accounts",
        icon: null,
        permissions: [Permissions.READ_LEDGER_ACCOUNT],
      },
      {
        label: "ledger_transactions",
        path: "/ledger-transactions",
        icon: null,
        permissions: [Permissions.READ_LEDGER_TRANSACTION],
      },
    ],
  },
  {
    label: "vehicles",
    path: null,
    icon: "directions_car",
    permissions: [Permissions.READ_VEHICLE, Permissions.READ_VEHICLE_MANUFACTURER],
    subItems: [
      {
        label: "vehicles",
        path: "/vehicles",
        icon: null,
        permissions: [Permissions.READ_VEHICLE],
      },
      {
        label: "vehicle_makes",
        path: "/vehicle-makes",
        icon: null,
        permissions: [Permissions.READ_VEHICLE_MANUFACTURER],
      },
    ],
  },
  {
    label: "credit",
    path: null,
    icon: "payments",
    permissions: [Permissions.READ_DEALER_CREDIT],
    subItems: [
      {
        label: "credits",
        path: "/credits",
        icon: null,
        permissions: [Permissions.READ_DEALER_CREDIT],
      },
      {
        label: "loan_simulation",
        path: "/loan-simulation",
        icon: null,
        permissions: [Permissions.READ_DEALER_CREDIT],
      },
      {
        label: "price_rules",
        path: "/price-rules",
        icon: null,
        permissions: [Permissions.READ_PRICING_RULE],
      },
    ],
  },
  {
    label: "payment_and_invoices",
    icon: "receipt",
    path: null,
    permissions: [Permissions.READ_AROS_INTEGRATION],
    subItems: [
      {
        label: "deposits",
        path: "/deposits",
        icon: null,
        permissions: [Permissions.READ_DEALER_DEPOSIT],
      },
      {
        label: "incoming_payments",
        path: "/payments",
        icon: null,
        permissions: [Permissions.READ_AROS_INTEGRATION],
      },
      {
        label: "customer_invoices",
        path: "/invoices",
        icon: null,
        permissions: [Permissions.READ_INVOICE],
      },
    ],
  },
  {
    label: "monitoring",
    path: null,
    icon: "legend_toggle",
    permissions: [
      Permissions.READ_MONITORING_LOG,
      Permissions.READ_MONITORING_SCHEDULE,
      Permissions.READ_ALERT,
      Permissions.READ_DOMAIN_EVENT,
    ],
    subItems: [
      {
        label: "monitoring_observations",
        path: "/monitoring/observations",
        icon: null,
        permissions: [Permissions.READ_MONITORING_LOG],
      },
      {
        label: "monitoring_schedules",
        path: "/monitoring/schedules",
        icon: null,
        permissions: [Permissions.READ_MONITORING_SCHEDULE],
      },
      {
        label: "alerts",
        path: "/alerts",
        icon: null,
        permissions: [Permissions.READ_ALERT],
      },
      {
        label: "event_logs",
        path: "/event-logs",
        icon: null,
        permissions: [Permissions.READ_DOMAIN_EVENT],
      },
    ],
  },
  {
    label: "integrations",
    path: null,
    icon: "extension",
    permissions: [
      Permissions.READ_FILE,
      Permissions.SEND_TRANSACTIONAL_EMAIL,
      Permissions.READ_VEHICLE,
      Permissions.READ_AROS_INTEGRATION,
    ],
    subItems: [
      {
        label: "files",
        path: "/files",
        icon: null,
        permissions: [Permissions.READ_FILE],
      },
      {
        label: "emails",
        path: "/emails",
        icon: null,
        permissions: [Permissions.READ_TRANSACTIONAL_EMAIL],
      },
      {
        label: "vin_decoder",
        path: "/integrations/vin-decoder",
        icon: null,
        permissions: [Permissions.READ_VEHICLE],
      },
      {
        label: "GTX",
        path: "/integrations/gtx",
        icon: null,
        permissions: [Permissions.READ_VEHICLE],
      },
      {
        label: "aros",
        path: "/integrations/aros",
        icon: null,
        permissions: [Permissions.READ_AROS_INTEGRATION],
      },
      {
        label: "fragus",
        path: "/integrations/fragus",
        icon: null,
        permissions: [Permissions.READ_VEHICLE],
      },
      {
        label: "carinfo",
        path: "/integrations/car-info",
        icon: null,
        permissions: [Permissions.READ_VEHICLE],
      },
      {
        label: "traficom",
        path: "/traficom",
        icon: null,
        permissions: [Permissions.UPDATE_VEHICLE_OWNERSHIP, Permissions.UPDATE_VEHICLE, Permissions.CREATE_FILE],
      },
      {
        label: "system_messages",
        path: "/system-messages",
        icon: null,
        permissions: [Permissions.READ_SYSTEM_MESSAGE],
      },
    ],
  },
];
