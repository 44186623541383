import { chakra } from "@chakra-ui/react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";

type Props = TextareaAutosizeProps & {
  layerStyle?: string;
};

const TextareaAutosizeInput = chakra(TextareaAutosize);

function AutosizeTextarea(props: Props) {
  return (
    <TextareaAutosizeInput value={props.value} onChange={props.onChange} layerStyle="autosizeTextarea" {...props} />
  );
}

export default AutosizeTextarea;
