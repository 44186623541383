import { AlertStateEnum, AlertTypesEnum } from "../alerts/types";
import { IAuthParams } from "../auth/types";
import { CreditProductKey, FinancingPartnerKey } from "../credit/types";
import { ITag } from "../domain-entity/types";
import { IRecipient } from "../emails/types";
import { Permissions } from "../permissions";
import { IVehicleType } from "../vehicle/types";

export enum IFeatureFlag {
  BLANCO_CONSUMER_LOANS = "blanco_loans_enabled",
  VEHICLE_INVENTORY = "vehicle_inventory",
  USER_MANAGEMENT = "user_management",
  CREDIT_BONUS_PROGRAM = "credit_bonus_program",
  OWN_STOCK_FINANCING = "own_stock_financing",
}

export enum DealerNotificationChannel {
  EMAIL = "email",
  SMS = "sms",
}

export enum BankRepresentationType {
  iban = "iban",
  account_number = "account_number",
}

interface ILocation {
  location_id: string;
  dealer_id: string;
  hf_tenant_key: HF_tenant_key;
  name: string;
  address: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface IGetDealerLocationsPayload extends IAuthParams {
  hf_tenant_key?: HF_tenant_key;
  query?: string;
  dealer_id?: string;
  offset?: number;
  limit?: number;
}

interface IGetDealerLocationsResponse {
  dealer_locations: ILocation[];
  total_count: number;
  limit: number;
  offset: number;
}

interface IGetDealerLocationPayload extends IAuthParams {
  location_id: string;
}

interface IPostDealerLocationPayload extends IAuthParams {
  dealer_id: string;
  name: string;
  address: string;
}

interface IPatchDealerLocationPayload extends IAuthParams {
  location_id: string;
  name: string;
  address: string;
}

interface IDeleteDealerLocationPayload extends IAuthParams {
  location_id: string;
}

type HF_tenant_key = "hf_se" | "hf_fi";

interface IRefKey {
  key: string;
  value: string;
}

interface IDealerAccountIndexItem {
  account_id: string;
  legal_entity_id: string;
  name: string;
  org_no: string;
  hf_tenant_key: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

type IBankAccountConfig = {
  representation_type?: BankRepresentationType;
  iban?: string;
  swift?: string;
  account_number?: string;
  clearing_number?: string;
};
interface IBankAccount {
  representation_type?: BankRepresentationType;
  iban?: {
    iban?: string;
    swift?: string;
  };
  account_number?: {
    clearing_number?: string;
    account_number?: string;
    bic?: string;
  };
}

interface IFeatureFlags {
  enabled: IFeatureFlag[];
}

// ALWAYS CHECK SCHEMA VERSION ON BACKEND BEFORE CHANGING THE DEALER ACCOUNT TYPES AND UPDATE VERSION IF NEEDED
export const DEALER_ACCOUNT_SCHEMA_VERSION = "1.3";

interface IDealerAccountConfiguration {
  currency?: string;
  credit_limit?: number;
  credit_limit_renew_date?: string;
  max_vehicle_price?: number;
  max_ltv?: number;
  default_interest_rate_discount?: string; // new field
  default_setup_fee?: number;
  risk_class?: "gold" | "silver" | "bronze";
  interest_accrual_scheme?: "daily" | "monthly";
  default_loan_servicer?: "aros" | "hf";
  default_loan_financier?: FinancingPartnerKey;
  default_credit_product?: CreditProductKey;
  insurance_renew_date?: string;
  vehicle_types?: IVehicleType[];
  pricing_rule_key?: string;
}
interface IDealerAccount {
  configuration?: IDealerAccountConfiguration;
  ref_keys: IRefKey[];
  preferences: { auto_dispatch_finance_report: true; notifications?: IDealerNotification[] };
  bank_account?: IBankAccount;
  sales_representative?: string;
  feature_flags?: IFeatureFlags;
}
// ALWAYS CHECK SCHEMA VERSION ON BACKEND BEFORE CHANGING THE DEALER ACCOUNT TYPES AND UPDATE VERSION IF NEEDED

interface IGetDealerAccountsPayload extends IAuthParams {
  query?: string;
  hf_tenant_key?: HF_tenant_key;
  legal_entity_id?: string;
  org_no?: string;
  order_by?: string;
  direction?: string;
  offset?: number;
  limit?: number;
}

interface IGetDealerAccountsResponse {
  dealer_accounts: IDealerAccountIndexItem[];
  total_count: number;
  limit: number;
  offset: number;
}

interface IGetDealerAccountPayload extends IAuthParams {
  account_id: string;
  revision_id?: string;
}

interface IGetDealerAccountResponse {
  account_id: string;
  revision_id: string;
  schema_version: string;
  legal_entity_id: string;
  legal_entity_name: string;
  hf_tenant_key: HF_tenant_key;
  dealer_account: IDealerAccount;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface IPostDealerAccountPayload extends IAuthParams {
  hf_tenant_key: HF_tenant_key;
  contact_id: string;
  dealer_account: IDealerAccount;
  schema_version: typeof DEALER_ACCOUNT_SCHEMA_VERSION;
}

interface IPostDealerAccountResponse {
  account_id: string;
  revision_id: string;
}

interface IChangeDealerAccountPayload extends IAuthParams {
  account_id: string;
  revision_id: string;
  dealer_account: IDealerAccount;
  schema_version: string;
}

interface IChangeDealerAccountResponse {
  updated: boolean;
  revision_id: string;
}

interface IDeleteDealerAccountPayload extends IAuthParams {
  account_id: string;
}

interface IDealerAccountFormValues {
  currency: string | null;
  credit_limit: number | null;
  credit_limit_renew_date: string | null;
  insurance_renew_date: string | null;
  max_vehicle_price: number | null;
  max_ltv: number | null;

  default_interest_rate_discount: string | null; // new field

  default_setup_fee: number | null;
  risk_class: "gold" | "silver" | "bronze" | null;
  default_loan_servicer: "aros" | "hf" | null;
  default_loan_financier: FinancingPartnerKey | null;
  default_credit_product: CreditProductKey | null;
  iban?: string;
  swift?: string;
  clearing_number?: string;
  account_number?: string;
  bic?: string;
  representation_type?: BankRepresentationType;
  sales_representative?: string;
  feature_flags?: IFeatureFlag[];
  vehicle_types: IVehicleType[];
  pricing_rule_key: string | null;
}

interface IGenerateStatusEmailPayload extends IAuthParams {
  account_id: string;
}

interface IGenerateStatusEmailResponse {
  subject: string;
  sender: string;
  to: IRecipient[];
  cc: IRecipient[];
  bcc: IRecipient[];
  attachments: string[];
  template_id: string;
  template_variables: Record<string, any>;
  tags: ITag[];
}

interface IGetCreditSummaryPayload extends IAuthParams {
  account_id: string;
}

interface IGetCreditSummaryResponse {
  currency: string;
  credit_used: number;
  credit_reserved?: number;
  credit_limit?: number;
  credit_available?: number;
  avg_repayment_days?: number;
  reservation_account_id?: string;
}

interface IGetDealerRequiredActionsPayload extends IAuthParams {
  account_id?: string;
  action_type?: AlertTypesEnum;
  state?: AlertStateEnum;
}

export enum ActionType {
  ownership_transfer_outstanding = "ownership_transfer_outstanding",
  payment_outstanding = "payment_outstanding",
  credit_limit_exceeded = "credit_limit_exceeded",
  contact_support = "contact_support",
}

type RequiredActions = {
  action_type: ActionType;
  created_at: string;
  updated_at: string;
  parameters: Record<string, any>;
  schema_version: string;
};

interface IGetDealerRequiredActionsResponse {
  actions: RequiredActions[];
}

interface IDealerRequiredActionsRowData {
  id: string | number;
  action_type: string | JSX.Element;
  created_at: string;
  updated_at: string;
}

interface IFetchFinancingAmountPayload extends IAuthParams {
  accountId: string;
  unit_price: number;
  currency: string;
}

interface IFetchFinancingAmountResponse {
  financing_limit: number;
}

interface IExportDealerAccountsRiskPayload extends IAuthParams {
  file_name: string;
}

interface IExportDealerAccountsRiskResponse {
  job_id: string;
}

interface IInitRepossessionPayload extends IAuthParams {
  account_id: string;
  initiated_at?: string;
  reason?: string;
}

interface IInitRepossessionResponse {
  created: number;
}

export enum IDealerAccountKycStatus {
  OK = "ok",
  NO_ACTIVE_APPLICATION = "no_active_application",
}

interface IDealerKycStatusResponse {
  status: IDealerAccountKycStatus;
  kyc_application_id: "string";
  created_at: "string";
  approved_at: "string";
  valid_until: "string";
}
interface IDealerKycStatusPayload extends IAuthParams {
  account_id: string;
}
interface INotificationListener {
  email?: string;
  phone_no?: string;
  channel: DealerNotificationChannel | undefined;
  guid?: string;
}
interface INotificationTypesSchema {
  label: string;
  listeners: INotificationListener[];
  sub_notification_types: INotificationTypesSchema[];
}

interface IDealerNotification extends INotificationListener {
  labels: string[];
}

interface IListenersAccumulator extends INotificationListener {
  label: string;
}

interface IConstructedNotifications {
  [key: string]: IDealerNotification;
}

interface IFetchNotificationTreePayload extends IAuthParams {}
type IFetchNotificationTreeResponse = Record<string, object | []>;

interface IFetchFeatureFlagsPayload extends IAuthParams {}

interface IFetchFeatureFlagsResponse {
  feature_flags: IFeatureFlag[];
}

interface IFetchUserPermissionPayload extends IAuthParams {}

interface IFetchUserPermissionResponse {
  permissions: Permissions[];
}

export type {
  IDealerAccountConfiguration,
  IFetchUserPermissionResponse,
  IFetchUserPermissionPayload,
  IFetchFeatureFlagsResponse,
  IFetchFeatureFlagsPayload,
  IFetchNotificationTreeResponse,
  IFetchNotificationTreePayload,
  IConstructedNotifications,
  IListenersAccumulator,
  IDealerNotification,
  INotificationTypesSchema,
  INotificationListener,
  ILocation,
  IGetDealerLocationsPayload,
  IGetDealerLocationsResponse,
  IGetDealerLocationPayload,
  IPostDealerLocationPayload,
  IPatchDealerLocationPayload,
  IDeleteDealerLocationPayload,
  HF_tenant_key,
  IDealerAccountIndexItem,
  IGetDealerAccountsPayload,
  IGetDealerAccountsResponse,
  IGetDealerAccountPayload,
  IGetDealerAccountResponse,
  IPostDealerAccountPayload,
  IPostDealerAccountResponse,
  IChangeDealerAccountPayload,
  IChangeDealerAccountResponse,
  IDeleteDealerAccountPayload,
  IDealerAccount,
  IRefKey,
  IDealerAccountFormValues,
  IGenerateStatusEmailPayload,
  IGenerateStatusEmailResponse,
  IGetCreditSummaryPayload,
  IGetCreditSummaryResponse,
  IGetDealerRequiredActionsPayload,
  IGetDealerRequiredActionsResponse,
  IDealerRequiredActionsRowData,
  IFetchFinancingAmountPayload,
  IFetchFinancingAmountResponse,
  IBankAccount,
  IBankAccountConfig,
  IExportDealerAccountsRiskPayload,
  IExportDealerAccountsRiskResponse,
  IInitRepossessionPayload,
  IInitRepossessionResponse,
  IDealerKycStatusResponse,
  IDealerKycStatusPayload,
};
