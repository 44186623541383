import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetPricingGlobalsPayload,
  IGetPricingGlobalsResponse,
  IGetPricingRulesPayload,
  IGetPricingRulesResponse,
  IGetPricingRulePayload,
  IGetPricingRuleResponse,
  IUpdatePricingRulePayload,
  IUpdatePricingRuleResponse,
  IDeletePricingRulePayload,
  IDeletePricingRuleResponse,
  IUpdatePricingRuleMetadataPayload,
  IUpdatePricingRuleMetadataResponse,
  IGetPricingRuleRevisionsPayload,
  IGetPricingRuleRevisionsResponse,
  IGetPricingInputsPayload,
  IGetPricingInputsResponse,
  IGetPricingOutputsPayload,
  IGetPricingOutputsResponse,
  IRunPricingEnginePayload,
  IRunPricingEngineResponse,
  IValidateExpressionPayload,
  IValidateExpressionResponse,
  IExportPricingRulePayload,
  IExportPricingRuleResponse,
  ICreateRulePayload,
  ICreateRuleResponse,
  ICloneRuleResponse,
  ICloneRulePayload,
  IRevertRulePayload,
  IRevertRuleResponse,
} from "./types";

export const pricingApi = createApi({
  reducerPath: "pricingApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Globals", "PricingRules", "PricingRule", "PricingRuleRevisions"],
  endpoints: builder => ({
    getPricingGlobals: builder.query<IGetPricingGlobalsResponse, IGetPricingGlobalsPayload>({
      query: payload => {
        const { accessToken } = payload;
        return {
          url: "/v1/pricing/globals",
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Globals"],
    }),
    getPricingRules: builder.query<IGetPricingRulesResponse, IGetPricingRulesPayload>({
      query: payload => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: "/v1/pricing/rule",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["PricingRules"],
    }),
    getPricingRule: builder.query<IGetPricingRuleResponse, IGetPricingRulePayload>({
      query: payload => {
        const { accessToken, rule_id, revision_id } = payload;
        return {
          url: `/v1/pricing/rule/${rule_id}`,
          params: { revision_id },
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["PricingRule"],
    }),
    updatePricingRule: builder.mutation<IUpdatePricingRuleResponse, IUpdatePricingRulePayload>({
      query: ({ accessToken, rule_id, ...payload }) => {
        return {
          url: `/v1/pricing/rule/${rule_id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: payload,
        };
      },
      invalidatesTags: ["PricingRule", "PricingRules", "PricingRuleRevisions"],
    }),
    deletePricingRule: builder.mutation<IDeletePricingRuleResponse, IDeletePricingRulePayload>({
      query: payload => {
        const { accessToken, rule_id } = payload;
        return {
          url: `/v1/pricing/rule/${rule_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["PricingRule", "PricingRules"],
    }),
    updatePricingRuleMetadata: builder.mutation<IUpdatePricingRuleMetadataResponse, IUpdatePricingRuleMetadataPayload>({
      query: payload => {
        const { accessToken, rule_id, input_schema_version, description } = payload;
        return {
          url: `/v1/pricing/rule/${rule_id}/metadata`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: {
            input_schema_version,
            description,
          },
        };
      },
      invalidatesTags: ["PricingRule"],
    }),
    getPricingRuleRevisions: builder.query<IGetPricingRuleRevisionsResponse, IGetPricingRuleRevisionsPayload>({
      query: payload => {
        const { accessToken, rule_id } = payload;
        return {
          url: `/v1/pricing/rule/${rule_id}/revision`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["PricingRuleRevisions"],
    }),
    getPricingInputs: builder.query<IGetPricingInputsResponse, IGetPricingInputsPayload>({
      query: payload => {
        const { accessToken, schema_version } = payload;
        return {
          url: "/v1/pricing/input_schema",
          method: "GET",
          params: { schema_version },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getPricingOutputs: builder.query<IGetPricingOutputsResponse, IGetPricingOutputsPayload>({
      query: payload => {
        const { accessToken, schema_version } = payload;
        return {
          url: "/v1/pricing/output_schema",
          method: "GET",
          params: { schema_version },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    runPricingEngine: builder.mutation<IRunPricingEngineResponse, IRunPricingEnginePayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/pricing/run_engine",
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    validateExpression: builder.mutation<IValidateExpressionResponse, IValidateExpressionPayload>({
      query: payload => {
        const { accessToken, expression } = payload;
        return {
          url: "/v1/pricing/validate_expression",
          method: "POST",
          body: { expression },
          headers: { Authorization: `Bearer ${accessToken}` },
        };
      },
    }),
    exportPricingRule: builder.mutation<IExportPricingRuleResponse, IExportPricingRulePayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/pricing/excel_export",
          method: "POST",
          body: payload,
          headers: { Authorization: `Bearer ${accessToken}` },
        };
      },
    }),
    createPricingRule: builder.mutation<ICreateRuleResponse, ICreateRulePayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/pricing/rule",
          method: "POST",
          body: payload,
          headers: { Authorization: `Bearer ${accessToken}` },
        };
      },
      invalidatesTags: ["PricingRules", "PricingRuleRevisions"],
    }),
    clonePricingRule: builder.mutation<ICloneRuleResponse, ICloneRulePayload>({
      query: ({ accessToken, rule_id, ...payload }) => {
        return {
          url: `/v1/pricing/rule/${rule_id}/copy`,
          method: "POST",
          body: payload,
          headers: { Authorization: `Bearer ${accessToken}` },
        };
      },
      invalidatesTags: ["PricingRules"],
    }),
    revertPricingRule: builder.mutation<IRevertRuleResponse, IRevertRulePayload>({
      query: ({ accessToken, rule_id, ...payload }) => {
        return {
          url: `/v1/pricing/rule/${rule_id}/revert`,
          method: "POST",
          body: payload,
          headers: { Authorization: `Bearer ${accessToken}` },
        };
      },
      invalidatesTags: ["PricingRules", 'PricingRuleRevisions'],
    }),
  }),
});

export const {
  useGetPricingGlobalsQuery,
  useGetPricingRulesQuery,
  useGetPricingRuleQuery,
  useUpdatePricingRuleMutation,
  useDeletePricingRuleMutation,
  useUpdatePricingRuleMetadataMutation,
  useGetPricingRuleRevisionsQuery,
  useGetPricingInputsQuery,
  useGetPricingOutputsQuery,
  useRunPricingEngineMutation,
  useValidateExpressionMutation,
  useExportPricingRuleMutation,
  useCreatePricingRuleMutation,
  useClonePricingRuleMutation,
  useRevertPricingRuleMutation,
} = pricingApi;
