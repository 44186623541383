import { Box, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import { Entities } from "../../domain-entity";
import useGetRevision from "../../hooks/useGetRevision";
import useTranslate from "../../locale/hooks/useTranslate";
import useGetNotificationTree from "../hooks/useGetNotificationTree";
import { useChangeDealerAccountMutation, useGetDealerAccountQuery } from "../service";
import { IDealerNotification, INotificationListener, INotificationTypesSchema } from "../types";
import NotificationField from "./NotificationField";
import {
  deleteListener,
  getNotificationsFromLayout,
  getPopulatedLayoutWithListeners,
  insertListener,
  updateListener,
} from "./notifications_structure";
import ConfirmationAlertDialog from "../../shared/confirmations/ConfirmationAlertDialog";
import { getDealerAccountSchemaVersionToUse } from "../utils";

type Props = {
  dealerId: string;
};

const DealerNotifications = ({ dealerId }: Props) => {
  const toast = useToast();
  const translate = useTranslate();
  const accessToken = useContext(AccessTokenContext);
  const dealerAccountRevId = useGetRevision(Entities.DEALER_ACCOUNT);
  const [editDealerAccount, { isLoading }] = useChangeDealerAccountMutation();

  const { schema, isLoading: fetchingNotificationTree } = useGetNotificationTree();
  const { data } = useGetDealerAccountQuery(
    {
      accessToken,
      account_id: dealerId,
      revision_id: dealerAccountRevId,
    },
    { skip: !dealerId }
  );

  const [tree, setTree] = useState<INotificationTypesSchema | undefined>(schema);
  const [listenerToRemove, setListener] = useState<INotificationListener | undefined>();

  useEffect(() => {
    if (!schema) return;
    const tree = getPopulatedLayoutWithListeners(data?.dealer_account?.preferences?.notifications || [], schema);
    setTree(tree);
  }, [data, schema]);

  const addListener = (listener: INotificationListener, label: string) => {
    if (!tree) return;
    const newTree = insertListener(tree, listener, label);
    setTree({ ...newTree });
  };

  const removeListener = (listener: INotificationListener) => {
    if (!tree) return;
    if (listener.channel === undefined) {
      const newTree = deleteListener(tree, listener);
      setTree({ ...newTree });
    } else {
      setListener(listener);
    }
  };

  const handleRemoveConfirmation = (listener: INotificationListener | undefined) => {
    if (!tree || !listener) return;
    const newTree = deleteListener(tree, listener);
    const notifications = getNotificationsFromLayout(newTree);
    handleSave(notifications);
  };

  const onListenerChange = (listener: INotificationListener) => {
    if (!tree) return;
    const newTree = updateListener(tree, listener);
    const notifications = getNotificationsFromLayout(newTree);
    handleSave(notifications);
  };

  const handleSave = (notifications: IDealerNotification[]) => {
    if (!data) return;

    const schemaVersionToUse = getDealerAccountSchemaVersionToUse(data);

    const dealerAccount = {
      ...data?.dealer_account,
      preferences: { ...data?.dealer_account.preferences, notifications },
    };

    editDealerAccount({
      accessToken,
      account_id: dealerId,
      revision_id: data?.revision_id,
      dealer_account: dealerAccount,
      schema_version: schemaVersionToUse,
    })
      .unwrap()
      .then(response => {
        toast({
          title: translate("dealer_account.edit_success"),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(error => {
        const message = error.error_message || translate("dealer_account.edit_error");
        toast({
          title: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setListener(undefined);
      });
  };

  return (
    <Box layerStyle="card">
      <Text textStyle="h2" mb="6">
        {translate("dealer_account.notifications.notifications_config")}
      </Text>

      {tree && !fetchingNotificationTree && (
        <NotificationField
          notification={tree}
          addListener={addListener}
          removeListener={removeListener}
          onListenerChange={onListenerChange}
        />
      )}
      <ConfirmationAlertDialog
        isOpen={!!listenerToRemove}
        closeModal={() => setListener(undefined)}
        title={translate("dealer_account.notifications.remove_listener_title")}
        message={translate("dealer_account.notifications.remove_listener_message")}
        confirmAction={() => handleRemoveConfirmation(listenerToRemove)}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default DealerNotifications;
